<template>
  <div>
    <el-drawer
      title="SMS түүх"
      :visible="isShow"
      :size="size"
      @close="closeFunction"
      style="align-items: center; height: 100%"
    >
      <span class="mr10 ml10">Салбар сонгох</span>
      <el-select size="mini" :value="selectedOutlet" @change="changeOutlet">
        <el-option
          v-for="(outlet, indexOutlet) in $root.chosenOutlets"
          :key="indexOutlet"
          :label="outlet.full_name_mon"
          :value="outlet._id">
        </el-option>
      </el-select>
      <el-table class="tableClss" size="mini" style="width: 100%" :data="historyData.hits" v-loading="loading || detailLoading">
        <el-table-column prop="desc" label="Тайлбар"></el-table-column>
        <el-table-column prop="user" label="Өөрчилсөн хэрэглэгч"></el-table-column>
        <el-table-column prop="created_at" label="Үүссэн огноо"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="getHistoryDetail(scope.row)">Дэлгэрэнгүй</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt20 mb20 text-right"
        background
        layout="total, prev, pager, next"
        :current-page="historyData.current_page"
        :page-count="historyData.total_page"
        :total="historyData.total"
        :page-size="10"
        @current-change="changePage"
      >
      </el-pagination>
    </el-drawer>
    <el-drawer
      title="Дэлгэрэнгүй түүх"
      :visible="showDetail"
      :size="size1"
      @close="closeDetailHistory"
      style="align-items: center; height: 100%"
    >
      <el-row v-if="logDetailData" class="p20">
        <el-row>
          <p class="text-bold">ШИНЭ</p>
          <el-col :span="12">
            <span>Төлөв:</span>
          </el-col>
          <el-col :span="12">
            <el-tag type="primary" size="mini" v-if="logDetailData.new.is_new_order_send_sms">Идэвхтэй</el-tag>
            <el-tag type="warning" size="mini" v-else>Идэвхгүй</el-tag>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <span>Утасны дугаар</span>
          </el-col>
          <el-col :span="12">
            <span>{{logDetailData.new.new_order_sms_phone}}</span>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <span>Өөрчилсөн хэрэглэгч</span>
          </el-col>
          <el-col :span="12">
            {{logDetailData.user}}
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <span>Тайлбар</span>
          </el-col>
          <el-col :span="12">
            {{logDetailData.desc}}
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <span>Үйлдэл</span>
          </el-col>
          <el-col :span="12">
            {{logDetailData.action}}
          </el-col>
        </el-row>
        <el-row>
          <p class="text-bold">ХУУЧИН</p>
          <el-col :span="12">
            <span>Төлөв:</span>
          </el-col>
          <el-col :span="12">
            <el-tag type="primary" size="mini" v-if="logDetailData.old.is_new_order_send_sms">Идэвхтэй</el-tag>
            <el-tag type="warning" size="mini" v-else>Идэвхгүй</el-tag>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <span>Утасны дугаар</span>
          </el-col>
          <el-col :span="12">
            <span>{{logDetailData.old.new_order_sms_phone ? logDetailData.old.new_order_sms_phone : '-'}}</span>
          </el-col>
        </el-row>
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
export default {
  name: 'History',
  created () {
    if (window.innerWidth < 600) {
      this.size = '90%'
      this.size1 = '80%'
    } else {
      this.size = '40%'
      this.size1 = '35%'
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    closeFunction: {
      type: Function,
      default: () => null
    },
    historyData: {
      type: Object,
      default: () => {}
    },
    currentPage: {
      type: Number,
      default: 1
    },
    changePage: {
      type: Function,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    },
    changeOutlet: {
      type: Function,
      default: () => null
    },
    selectedOutlet: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      size: '',
      size1: '',
      logDetailData: null,
      detailLoading: false,
      showDetail: false
    }
  },
  methods: {
    closeDetailHistory () {
      this.showDetail = false
    },
    getHistoryDetail (data) {
      this.detailLoading = true
      mAxios
        .post('/outlet/infoHistoryDetail', {
          history_id: data._id,
          outlet_id: data.title
        })
        .then((res) => {
          if (res.data.status === 'success') {
            const tempData = { ...res.data.data, ...data }
            this.logDetailData = tempData
            this.detailLoading = false
            this.showDetail = true
          } else {
            this.detailLoading = false
          }
        }).catch((err) => {
          this.detailLoading = false
        })
    }
  }
}
</script>
<style>
</style>
