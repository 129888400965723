<template>
  <div class="under-list-body">
    <button @click="item.pay_status === 'not_paid' && getServices()" class="cursor-pointer status-list-button">{{item.pay_status === 'not_paid' ? 'Төлбөр хүлээгдэж байгаа' : 'Төлбөр төлсөн'}}<i v-if="item.pay_status === 'not_paid'" class="el-icon-refresh ml5"></i></button>
    <div class="single-list-item-header">{{item.promotion_items[0].promotion_product_name}}</div>
    <div class="single-list-item-body">
      <div class="single-list-item-body-inner" v-if="item.pay_status === 'paid'">
        <span style="font-weight: 400;">Хийсэн эсэх</span>
        <span style="font-weight: 600;">{{item.promotion_items[0].is_done ? 'Тийм' : 'Үгүй'}}</span>
      </div>
      <div class="single-list-item-body-inner" v-if="Object.prototype.hasOwnProperty.call(item.promotion_items[0], 'duration_time')">
        <span style="font-weight: 400;">Хугацаа</span>
        <span style="font-weight: 600;">{{item.promotion_items[0].duration_time}} хоног</span>
      </div>
      <div class="single-list-item-body-inner">
        <span style="font-weight: 400;">Үнэ</span>
        <span style="font-weight: 600;">{{$helpers.formatPrice(item.promotion_items[0].item_price)}} ₮</span>
      </div>
    </div>
    <div class="service-list-bottom-container">
      <button class="cursor-pointer invoice-list-button" @click="checkPayment(item.promotion_id, 'invoice')">Нэхэмжлэх</button>
      <button class="cursor-pointer invoice-list-button payment-list-button" v-if="item.pay_status === 'not_paid'" @click="checkPayment(item.promotion_id, 'tuluh')">Төлөх</button>
      <button class="cursor-pointer invoice-list-button payment-list-button" v-else @click="checkPayment(item.promotion_id, 'ebarimt')">И-баримт</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleServiceListItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    getServices: {
      type: Function,
      default: () => null
    },
    setData: {
      type: Function,
      default: () => null
    },
    checkPayment: {
      type: Function,
      default: () => null
    }
  }
}
</script>

<style>
</style>
