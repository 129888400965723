<template>
  <div @click="handleFunction(service)" class="cursor-pointer select-item-container">
    <div class="select-item-body">
      <span class="select-item-header">{{service.name_mon}}</span>
      <div class="select-item-line"></div>
      <span v-html="htmlContent">{{service.desc}}</span>
    </div>
    <div class="select-item-arrow">
      <img src="../../../../../assets/sociel/fi-rr-angle-small-down.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleServiceItem',
  props: {
    service: {
      type: Object,
      default: () => {}
    },
    handleFunction: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      htmlContent: this.service.desc
    }
  }
}
</script>

<style>
</style>
