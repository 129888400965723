<template>
  <div>
    <div>
      <div style="display:flex; margin-bottom:1%;">
        <title-item title="Багц үйлчилгээ" />
        <el-button style="background-color:#ffb500; color:white; border-radius:200px; margin-left:2%;" size="medium"
          @click="getOwnService">{{ isOwnService ? 'Үйлчилгээ авах' : 'Миний үйлчилгээ' }}</el-button>
      </div>
      <div style="display:flex;" v-if="isOwnService">
        <div class="slide" v-loading="loading">
          <div class="listcss">
            <div @click="packageDetail(promotion)" v-for="(promotion, promotionIndex) in allPromotion"
              :key="promotionIndex" :class="promotion.promotion_id === id ? 'listPackageActive' : 'listPackage'">
              <div class="textClass" style="padding-top:10%;padding-bottom:1%;">
                {{ promotion.parent_item.promotion_product_name }}
              </div>
              <div style="color:red" v-if="promotion.desc">{{ promotion.desc }}</div>
              <div class="textClass">{{ $helpers.formatPrice(promotion.pay_total) }}₮</div>
              <div style="padding-left:5%; font-size:12px; width:45%; color:#808080;">
                Идэвхижүүлэлт үргэлжлэх хугацаа: {{ promotion.promotion_items[0].duration_unit === 'week' ?
                  promotion.promotion_items[0].duration_time * 7 : promotion.promotion_items[0].duration_time }}
                <span v-if="promotion.promotion_items[0].duration_unit === 'day'">Өдөр</span>
                <span v-if="promotion.promotion_items[0].duration_unit === 'week'">хоног</span>
                <span v-if="promotion.promotion_items[0].duration_unit === 'month'">Сар</span>
              </div>
              <h1
                :style="promotion.pay_status === 'not_paid' ? 'font-size: 15px; color:red; padding-left: 5%;' : 'font-size: 15px; color:#7CBB00; padding-left: 5%;'">
                {{ promotion.pay_status === 'not_paid' ? 'Төлбөр хүлээгдэж байгаа' : 'Төлөгдсөн' }}
              </h1>
              <div style="display:flex; align-items: center; margin-left:5%;">
                <el-button size="small" class="buttons" style="width:60%;"
                  @click="pay(promotion.promotion_id, 'invoice')">Нэхэмжлэх</el-button>
                <el-button v-if="promotion.pay_status === 'not_paid'" @click="pay(promotion.promotion_id, '')"
                  size="small" class="buttons" style="width:30%; background-color: #37d461;">Төлөх</el-button>
                <el-button v-else-if="promotion.pay_status === 'paid'" @click="ebarimt(promotion.promotion_id)"
                  size="small" class="buttons" style="width:30%; background-color: #1f417e;">И-Баримт</el-button>
              </div>
              <div v-if="promotion.pay_status === 'not_paid'" style="
                    display: flex;
                    align-items: center;
                    margin-left: 5%;
                    margin-right: 5%;
                  ">
                <el-button size="small" class="buttons" style="width:98%;" v-loading="checkLoad"
                  @click="checkPayment(promotion.promotion_id)"><i class="el-icon-refresh"></i>Төлбөр шалгах</el-button>
              </div>
              <div class="detailPackage" v-loading="loadBody">
                <div class="items"
                  v-for="(items, itemIndex) in promotion.promotion_items.filter(res => res.type === 'in_package')"
                  :key="itemIndex">
                  <i class="el-icon-success" :style="items.is_done ? 'color: #7cbb00' : 'color: #FFB500'"></i>
                  <span>{{ items.is_done ? ' Хийгдсэн' : ' Хийгдэж байгаа' }}</span>
                  <div>{{ items.promotion_product_name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="discountBody" v-loading="loadBody">
          <div style="display:flex; flex-wrap:wrap;">
            <div class="items" v-for="(items, itemIndex) in promotionItems" :key="itemIndex">
              <i class="el-icon-success" :style="items.is_done ? 'color: #7cbb00' : 'color: #FFB500'"></i>
              <span>{{ items.is_done ? ' Хийгдсэн' : ' Хийгдэж байгаа' }}</span>
              <div>{{ items.promotion_product_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="header" v-loading="loading">
        <table class="tableCss">
          <colgroup></colgroup>
          <colgroup v-for="pack in packagePromotion.length" :key="pack"></colgroup>
          <thead>
            <tr>
              <th>
                <div v-if="timerOutput">
                  <span>Хямдрал дуусах хугацаа:</span>
                  <h2 style="color: #f79e05;">{{ timerOutput }}</h2>
                </div>
              </th>
              <th v-for="(marketingPackage, packageIndex) in packagePromotion" :key="packageIndex">
                <h2>{{ marketingPackage.name_mon }}<br />
                  <span style="color:red; font-size:15px;" v-if="marketingPackage.desc">{{ marketingPackage.desc }}<br /></span>
                  {{ $helpers.formatPrice(marketingPackage.pay_total) }}₮
                </h2>
                <span class="descSpan">Идэвхижүүлэлт үргэлжлэх хугацаа: {{ marketingPackage.duration_unit === 'week' ?
                  marketingPackage.duration_time * 7 : marketingPackage.duration_time }}
                  <span v-if="marketingPackage.duration_unit === 'day'">Өдөр</span>
                  <span v-if="marketingPackage.duration_unit === 'week'">хоног</span>
                  <span v-if="marketingPackage.duration_unit === 'month'">Сар</span></span>
                <el-button class="button" @click="getService(marketingPackage)">Үйлчилгээ авах</el-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pService, pServiceIndex) in calculatedData" :key="pServiceIndex">
              <th>{{ pService.name }}</th>
              <td v-for="(a, aIndex) in pService.child_items" :key="aIndex">
                <i v-if="a.status === 'active'" class="el-icon-success" style="color:#ffb500; font-size:16px;"></i>
                <b style="text-align: center;"> {{ a.desc }}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mobileTableCss">
          <el-select v-model="packageType" @change="changeType(packageType)"
            style="width: 98%; margin-bottom: 5%; margin-top:2%;">
            <el-option v-for="(marketingPackage, packageIndex) in packagePromotion" :key="packageIndex"
              :label="marketingPackage.name_mon" :value="marketingPackage.promotion_product_id" />
          </el-select>
          <div v-if="packageType">
            <div style="display:flex; margin-bottom:5%;">
              <el-card style="border-radius:15px; height:80px; width:50%;">
                <span style="font-size: 12px; color:#7CBB00;">Үйлчилгээний үнэ:<br /></span>
                <b style="font-size: 18px;">{{ packageTypeData.pay_total }}₮</b>
              </el-card>
              <el-card style="border-radius:15px; height:80px; width:50%; margin-left:2%; padding-bottom:0;">
                <span style="font-size: 12px; color:#7CBB00;">Идэвхижүүлэлт үргэлжлэх хугацаа<br /></span>
                <b style="font-size: 16px;">
                  {{ packageTypeData.duration_unit === 'week' ? packageTypeData.duration_time * 7 :
                    packageTypeData.duration_time }}
                  <span v-if="packageTypeData.duration_unit === 'day'">Өдөр</span>
                  <span v-if="packageTypeData.duration_unit === 'week'">хоног</span>
                  <span v-if="packageTypeData.duration_unit === 'month'">Сар</span>
                </b>
              </el-card>
            </div>
            <div style="border: 1px solid lightgrey; border-radius: 10px; padding: 5%;">
              <b style="font-size:16px;">Багцад хамрагдах үйлчилгээ</b>
              <div v-for="(typeData, typeDataIndex) in packageTypeData.child_items" :key="typeDataIndex"
                style="display:flex; justify-content:space-between; margin-top:4%;">
                <span>{{ typeData.name_mon }} - <b>{{ typeData.price }}</b></span>
                <span><i v-if="typeData.status === 'active'" class="el-icon-success"
                    style="color:#ffb500; font-size:16px;"></i></span>
              </div>
            </div>
            <el-button class="button" @click="getService(packageTypeData)">Үйлчилгээ авах</el-button>
          </div>
        </div>
      </div>
    </div>
    <payment-info v-if="isService"
      :createInvoice="createPromotionInvoice"
      :selectedInvoice="selectedInvoice"
      :isShow="isService"
      :close="close"
      :done="close"
      :order="orderData"/>
    <ebarimt v-if="isEbarimt" :isShow="isEbarimt" :ebarimtData="ebarimtData" :close="closeEbarimt" />
    <el-drawer title="Нэхэмжлэх" v-if="isInvoice" :visible="isInvoice" :size="size" @close="closeDialog">
      <div style="display:flex; justify-content:center; margin-right:3%;">
        <invoice :title="title" :payment="payment" :price="packageData ? packageData.pay_total : payTotal" />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import TitleItem from '../../../../components/items/titleItem.vue'
import mAxios from '../../../../helper/axiosInstance'
import ebarimt from './component/ebarimt.vue'
import invoice from './component/invoice.vue'
import paymentInfo from './component/paymentInfo.vue'
export default {
  name: 'packageService',
  components: {
    TitleItem,
    paymentInfo,
    invoice,
    ebarimt
  },
  data () {
    return {
      packagePromotion: [],
      packageServices: [],
      loading: false,
      calculatedData: [],
      isService: false,
      packageData: null,
      orderData: null,
      payTotal: 0,
      allPromotion: [],
      isOwnService: false,
      promotionItems: [],
      orderRefer: '',
      isInvoice: false,
      checkLoad: false,
      load: false,
      isEbarimt: false,
      ebarimtData: {},
      packageType: '',
      packageTypeData: {},
      size: '',
      title: '',
      loadBody: false,
      id: '',
      countDownToTime: new Date('November 30, 2023 23:59:59'),
      timerOutput: null,
      selectedInvoice: null
    }
  },
  created () {
    this.getPackageService()
    this.packageList()
    if (window.innerWidth < 600) {
      this.size = '95%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '65%'
    } else {
      this.size = '40%'
    }
  },
  mounted () {
    setInterval(() => { this.startTimer() }, 1000)
  },
  methods: {
    async createPromotionInvoice (name, id) {
      const body = {
        order_id: id,
        payment_name: name
      }
      await mAxios.post('promotion/createPromotionInvoice', body).then((response) => {
        if (response.data.status === 'success') {
          this.selectedInvoice = response.data.data
          this.packageList()
          this.$message.success({
            message: 'Амжилттай үүслээ',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: response.data.message
          })
        }
      })
    },

    startTimer: function () {
      const timeNow = new Date().getTime()
      const timeDifference = this.countDownToTime - timeNow
      const millisecondsInOneMinute = 60000
      const millisecondsInOneHour = millisecondsInOneMinute * 60
      const millisecondsInOneDay = millisecondsInOneHour * 24
      const differenceInDays = timeDifference / millisecondsInOneDay
      const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour
      const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute
      const remainingDays = Math.floor(differenceInDays)
      const remainingHours = Math.floor(remainderDifferenceInHours)
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes)
      if (remainingDays > 0 || remainingHours > 0 || remainingMinutes > 0) {
        this.timerOutput = remainingDays + ' Өдөр ' + ' ' + remainingHours + ' Цаг ' + ' ' + remainingMinutes + ' Минут '
      } else {
        this.timerOutput = null
      }
    },
    packageDetail (packageData) {
      this.loadBody = true
      this.promotionItems = []
      this.id = packageData.promotion_id
      packageData.promotion_items.map(res => {
        if (res.type === 'in_package') {
          this.promotionItems.push(res)
          this.loadBody = false
        }
      })
    },
    getOwnService () {
      this.getPackageService()
      this.packageList()
      this.isOwnService = !this.isOwnService
    },
    async packageList () {
      this.promotionItems = []
      const payload = {
        size: 100,
        from: 0,
        outlet_id: this.$root.isAdmin ? '' : this.$root.outlet._id,
        service_type: 'package',
        status: ''
      }
      this.loading = true
      mAxios.post('/promotion/getAllPromotion', payload).then(response => {
        if (response.data.status === 'success') {
          this.allPromotion = response.data.data.hits
          this.promotionItems = response.data.data.hits.length > 0 ? response.data.data.hits[0].promotion_items.filter(items => items.type === 'in_package') : []
          this.id = response.data.data.hits.length > 0 ? response.data.data.hits[0].promotion_id : ''
          this.loading = false
        } else {
          this.$message.error({
            message: response.data.message
          })
          this.loading = false
        }
      })
    },
    getPackageService () {
      this.loading = true
      const payload = {
        category_name: 'package_promotion'
      }
      mAxios.post('/promotion/getPromotionProductsByCategory', payload).then(res => {
        this.packagePromotion = res.data.data.promotion_products
        this.packageType = res.data.data.promotion_products[0].promotion_product_id
        this.packageTypeData = res.data.data.promotion_products[0]
        this.packageServices = res.data.data.child_names.map((value) => {
          return { name: value }
        })
        this.packageServices.forEach(element => {
          element.child_items = []
          res.data.data.promotion_products.map(el2 => {
            const foundData = el2.child_items.find(el3 => el3.name_mon === element.name)
            if (foundData) {
              element.child_items.push(foundData)
            } else {
              element.child_items.push([])
            }
          })
        })
        this.calculatedData = this.packageServices
        this.loading = false
      })
    },
    async checkPayment (id) {
      this.checkLoad = true
      await mAxios.get('promotion/' + id).then(res => {
        if (res.data.status === 'success') {
          this.checkLoad = false
        }
        this.getPackageService()
        this.packageList()
      })
    },
    async getService (packageData) {
      this.$confirm('Та үйлчилгээ авахдаа итгэлтэй байна уу?', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      })
        .then(async () => {
          this.loading = true
          this.packageData = packageData
          let promotionItems = []
          promotionItems = [{
            promotion_product_name: packageData.name_mon,
            category_name: packageData.category_name,
            promotion_product_id: packageData.promotion_product_id,
            item_price: packageData.price,
            service_name: packageData.service_name,
            type: packageData.type,
            duration_time: packageData.duration_time,
            duration_unit: packageData.duration_unit
          }]
          packageData.child_items.map(res => {
            promotionItems.push({
              promotion_product_name: res.name_mon,
              category_name: packageData.category_name,
              promotion_product_id: res.promotion_product_id,
              item_price: res.price,
              service_name: res.service_name,
              type: res.type
            })
          })
          const payload = {
            outlet_id: this.$root.isAdmin ? this.$root.chosenOutlets[0]._id : this.$root.outlet._id,
            outlet_name_eng: this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_eng : this.$root.outlet.full_name_eng,
            outlet_name_mon: this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_mon : this.$root.outlet.full_name_mon,
            service_type: 'package',
            vat_type: this.$root.isAdmin ? this.$root.chosenOutlets[0].vat_type === 'company' ? 3 : 1 : this.$root.outlet.vat_type === 'company' ? 3 : 1,
            vat_regno: this.$root.isAdmin ? (this.$root.chosenOutlets[0].vat_type === 'company' ? this.$root.chosenOutlets[0].vat_regno : undefined) : this.$root.outlet.vat_type === 'company' ? this.$root.outlet.vat_regno : undefined,
            vat_company_name: this.$root.isAdmin ? this.$root.chosenOutlets[0].company_name : this.$root.outlet.company_name,
            payment_name: 'qpay',
            email: this.$root.outlet.email,
            promotion_items: promotionItems
          }
          await mAxios.post('/promotion', payload).then(res => {
            if (res.data.status === 'success') {
              this.title = res.data.data.promotion_items[0].promotion_product_name
              this.isService = true
              this.orderRefer = res.data.data.order.order_refer
              this.orderData = res.data.data
              this.loading = false
            } else {
              this.$message.error({
                message: res.data.message,
                type: 'error'
              })
              this.loading = false
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Буцлаа'
          })
        })
    },
    closeEbarimt () {
      this.isEbarimt = false
    },
    close () {
      this.selectedInvoice = null
      this.isInvoice = true
      this.isService = false
      this.packageList()
    },
    closeDialog () {
      this.isInvoice = false
    },
    async pay (id, type) {
      this.loading = true
      await mAxios.get('promotion/' + id).then(res => {
        if (res.data.status === 'success') {
          this.orderRefer = res.data.data.order.order_refer
          this.payTotal = res.data.data.pay_total
          this.orderData = res.data.data
          this.title = res.data.data.promotion_items[0].promotion_product_name
          if (type === 'invoice') {
            this.isInvoice = true
          } else {
            this.isService = true
          }
        } else {
          this.$message({
            message: 'Алдаа гарлаа',
            type: 'error'
          })
        }
        this.loading = false
      })
    },
    async ebarimt (id) {
      this.load = true
      await mAxios.get('promotion/' + id).then(res => {
        if (res.data.status === 'success') {
          this.isEbarimt = true
          this.ebarimtData = res.data.data.order.ebarimt
        } else {
          this.$message({
            message: 'Алдаа гарлаа',
            type: 'error'
          })
        }
        this.load = false
      })
    },
    payment () {
      this.closeDialog()
      this.isService = true
    },
    changeType (type) {
      this.packagePromotion.map(res => {
        if (res.promotion_product_id === type) {
          this.packageTypeData = res
        }
      })
    }
  }
}
</script>
<style scoped>
.header {
  display: flex;
}

.descSpan {
  width: 45%;
}

table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

th,
td {
  font-family: inherit;
  font-size: .875em;
  line-height: 1.45;
  color: #444;
  vertical-align: middle;
  padding: 1em;
}

th {
  font-weight: 600;
}

colgroup:nth-child(1) {
  width: 10%;
  border: 0 none;
}

colgroup:nth-child(2) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(3) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(4) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(5) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(6) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(7) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(8) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(9) {
  width: 10%;
  border: 1px solid #ccc;
}

colgroup:nth-child(10) {
  width: 10%;
  border: 1px solid #ccc;
}

thead th {
  background: #def4fe;
  background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%);
  text-align: center;
  position: relative;
  border-bottom: 1px solid #ccc;
  font-weight: 400;
  color: #999;
}

thead th:nth-child(1) {
  background: transparent;
}

tbody th {
  background: #fff;
  border: 1px solid #ccc;
}

tbody td {
  background: #fff;
  text-align: center;
}

tbody tr:nth-child(even) th,
tbody tr:nth-child(even) td {
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-width: 1px 0 1px 1px;
}

tbody tr:last-child td {
  border-bottom: 0 none;
}

.button {
  margin-top: 5%;
  width: 180px;
  color: black;
  font-weight: bold;
  border: 1px solid #ffb500;
  border-radius: 8px;
}

.button:hover {
  background-color: #ffb500;
  color: black;
  font-weight: bold;
}

.listcss {
  width: 82%;
  background-color: rgb(244, 244, 244);
  border-radius: 15px;
  padding: 4% 4% 4% 4%;
  margin-top: 4%;
  max-height: 100%;
  overflow: auto;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

.imageCss {
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
  width: 100%;
  height: 150px;
}

.textClass {
  font-size: 16px;
  font-weight: bold;
  padding-left: 5%;
  padding-bottom: 2%;
}

.listPackage {
  border-radius: 20px;
  border: 1px solid lightgrey;
  background-color: rgb(205, 201, 201);
  margin-bottom: 3%;
  padding-bottom: 3%;
}

.listPackageActive {
  border-radius: 20px;
  border: 1px solid lightgrey;
  background-color: white;
  margin-bottom: 3%;
  padding-bottom: 3%;
}

.slide {
  display: flex;
  flex-direction: column;
  height: 78vh;
  width: 480px;
  background-color: #ffffff;
}

@media screen and (max-width: 900px) {
  .discountBody {
    display: none;
  }

  .items {
    background-color: rgb(213, 211, 211);
    margin: 0px 1% 2% 2%;
    padding: 2% 5%;
    border-radius: 15px;
  }

  .detailPackage {
    display: flex;
    flex-wrap: wrap;
    margin-left: 4%;
    margin-top: 5%;
  }

  .tableCss {
    display: none;
  }
}

@media screen and (min-width: 901px) {
  .discountBody {
    width: 70%;
    background-color: rgb(244, 244, 244);
    color: rgb(78, 77, 77);
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
  }

  .items {
    background-color: #fff;
    margin-top: 2%;
    margin-left: 1%;
    padding: 2% 2%;
    border-radius: 15px;
  }

  .detailPackage {
    display: none;
  }

  .mobileTableCss {
    display: none;
  }
}

.buttons {
  width: 80%;
  color: white;
  background-color: #ffb500;
  border-radius: 8px;
  margin-top: 2%;
}</style>
