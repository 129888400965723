<template>
  <div>
    <el-drawer
      :title="'Edit ' + type"
      :visible="isShow"
      @open="onOpen"
      @close="onClose"
      :size="size"
      style="align-items: center; height: 100%"
    >
      <div class="warn">
        <div style="background-color:red; width:2%; border-top-left-radius: 0.5rem; border-bottom-left-radius: 0.5rem;"></div>
        <i class="el-icon-warning" style="font-size: 38px; color: #e62222; display:flex; align-items:center; padding-left:2%;"/>
        <div class="ml20">
          <p>1. Утасны дугаарын мэдээлэл оруулахгүй.</p>
          <p>2. Өөрсдийн хүргэлт, урамшуулалын мэдээлэл оруулж болохгүй.</p>
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
        <el-form-item
          :label="type === 'story' ? 'Story name' : 'Highlight name'"
        >
          <el-input v-model="form.story_name" style="width:50%" />
        </el-form-item>
        <el-form-item>
          <el-upload
            v-model="form.story_url"
            class="avatar-uploader"
            action=""
            fit="cover"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="file => uploadImage(file)"
            style="width:500px;"
          >
            <el-image
              v-if="form.story_url"
              :src="form.story_url"
              class="avatar"
              style="width:120px; height:120px;"
            />
            <h1>
              <i slot="default" class="el-icon-plus">
                Зураг солих
              </i>
            </h1>
          </el-upload>
        </el-form-item>
        <div v-if="type !== 'story'">
          <el-form-item label="Stories">
            <el-card>
              <div style="display:flex; flex-direction: row; flex-wrap: wrap;">
                <div
                  v-for="x in form.stories"
                  :key="x"
                  :class="x.isChecked ? 'activeStory' : 'story'"
                >
                  <i @click="remove(x)" class="el-icon-close check"></i>
                  <el-image
                    v-if="x.story_url"
                    :src="x.story_url"
                    class="avatar"
                    style="width:80px; height:80px; vertical-align: middle; border-radius: 50%;"
                  />
                  <div style="margin-top:10%;">
                    <span>{{ x.story_name }}</span>
                  </div>
                </div>
                <div
                  @click="isStoriesVisible = true"
                  style="margin-left:2%; margin-top:3%;"
                >
                  <el-avatar class="cursor-pointer" :size="80">
                    <i class="el-icon-plus"></i>
                  </el-avatar>
                </div>
              </div>
            </el-card>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="success" @click="save">Хадгалах</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer
      title="Story List"
      :visible.sync="isStoriesVisible"
      :size="size"
      @open="getAllStories"
      style="align-items: center; height: 100%"
    >
      <div>
        <el-card
          v-if="
            this.form &&
              Object.prototype.hasOwnProperty.call(this.form, 'stories')
          "
        >
          <div style="display:flex; flex-direction: row; flex-wrap: wrap;">
            <div
              v-for="x in allStoryData.filter(
                el => !this.computedAllStories.includes(el._id)
              )"
              :key="x"
              :class="x.isChecked ? 'activeStory' : 'story'"
            >
              <el-checkbox v-model="x.isChecked" class="check"></el-checkbox>
              <el-image
                v-if="x.story_url"
                :src="x.story_url"
                class="avatar"
                style="width:80px; height:80px; vertical-align: middle; border-radius: 50%;"
              />
              <div style="margin-top:10%;">
                <span>{{ x.story_name }}</span>
              </div>
            </div>
          </div>
        </el-card>
        <el-button @click="add">add</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import { getBase64 } from '../../../../../helper/custom'
export default {
  name: 'Story',
  created () {
    this.getOneStory()
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else {
      this.size = '30%'
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    storyId: {
      type: String,
      default: String
    },
    closeFunction: {
      type: Function,
      default: () => null
    },
    type: {
      type: String,
      default: String
    }
  },
  data () {
    return {
      size: '',
      form: {},
      isStoriesVisible: false,
      allStoryData: [],
      loading: false
    }
  },
  computed: {
    computedAllStories: function () {
      if (Object.prototype.hasOwnProperty.call(this.form, 'stories')) {
        return this.form.stories.map(el2 => el2.story_id)
      }
      return []
    }
  },
  methods: {
    async getAllStories () {
      const payload = {}
      payload.size = 1000
      payload.from = 0
      payload.outlet_ids = this.$root.outletIds
      payload.status = 'expired'
      payload.type = 'story'
      await mAxios.post('story/getAllStories', payload).then(res => {
        this.allStoryData = res.data.data
      })
    },
    async getOneStory () {
      await mAxios
        .get('/story/getOneStory?story_id=' + this.storyId)
        .then(res => {
          this.form = res.data.data
        })
    },
    onOpen () {
      this.getOneStory()
    },
    onClose () {
      this.closeFunction()
    },
    async uploadImage (file) {
      try {
        this.loading = true
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        await getBase64(file.raw).then(res => {
          mAxios
            .post('other/uploadStory', {
              base64: res
            })
            .then(response => {
              if (response.data.status === 'success') {
                this.form.story_url = response.data.data
                this.loading = false
              }
            })
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    remove (index) {
      for (let i = 0; i < this.form.stories.length; i++) {
        if (this.form.stories[i].story_id === index.story_id) {
          this.form.stories.splice(i, 1)
        }
      }
      this.form.child_id = this.form.stories.map(el => el.story_id)
    },
    add () {
      const stories = []
      this.allStoryData.forEach(res => {
        if (res.isChecked) {
          stories.push(res)
        }
      })
      this.form.stories = [...this.form.stories, ...stories]
      this.form.child_id = this.form.stories.map(el => el.story_id)
      this.isStoriesVisible = false
    },
    async save () {
      this.form.created_partner_id = this.$root.outlet_id
      await mAxios.post('/story/updateStory', this.form).then(res => {
        if (res.data.status === 'success') {
          this.$message.success({
            message: 'Амжилттай хадгаллаа',
            type: 'success'
          })
          this.onClose()
        } else {
          this.$message.warning({
            message: res.data.message,
            type: 'warning'
          })
        }
      })
    }
  }
}
</script>
<style>
.activeStory {
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.story {
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 80%;
  font-size: 0.8vw;
  cursor: pointer;
}
.warn {
  display: flex;
  border: 0px solid #8c939d;
  box-shadow: inset 2px 2px 4px 5px rgb(0 0 0 / 0.05);
  margin: 0px 2%;
  border-radius: 12px;
  margin-bottom: 2%;
}
</style>
