<template>
  <div style="margin-left:2%; width:100%;">
    <el-row class="invoice_body" id="invoice">
      <el-row type="flex" justify="space-between" class="invoice_description">
        <el-col :span="16">НХМаягт Т-1</el-col>
        <el-col :span="8">Сангийн сайдын 2017 оны 12 дугаар сарын 5-ны өдрийн 347 тоот тушаалын хавсралт</el-col>
      </el-row>
      <el-row type="flex" justify="center" class="invoice_header">НЭХЭМЖЛЭХ № 18</el-row>
      <el-row class="mb10">
        <el-col :span="15"><strong>Нэхэмжлэгч:</strong></el-col>
        <el-col :span="9"><strong>Төлөгч:</strong></el-col>
      </el-row>
      <el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Байгууллагын нэр:</el-col>
              <el-col :span="14" class="border_bottom">ТокТок ХХК</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">НӨАТ төлөгчийн дугаар:</el-col>
              <el-col :span="14" class="border_bottom">6183352</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Утас, Факс:</el-col>
              <el-col :span="14" class="border_bottom">345525</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Төлбөр хийх хугацаа:</el-col>
              <el-col :span="14" class="border_bottom">{{ generateNowDate() }}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Банкны нэр:</el-col>
              <el-col :span="14" class="border_bottom">ХААН ТӨВ БАНК</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Дансны дугаар:</el-col>
              <el-col :span="14" class="border_bottom">5173108943</el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Байгууллагын нэр:</el-col>
              <el-col :span="14" class="border_bottom">{{$root.outlet.company_name}}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">НӨАТ төлөгчийн дугаар:</el-col>
              <el-col :span="14" class="border_bottom">{{$root.outlet.vat_regno}}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Хаяг:</el-col>
              <el-col :span="14" class="border_bottom">{{ $root.outlet.address }}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb20" type="flex" align="bottom">
              <el-col :span="10">Гэрээний дугаар:</el-col>
              <el-col :span="14" class="border_bottom"></el-col>
            </el-row>
            <el-row :gutter="20" class="mb20">
              <el-col :span="10">Нэхэмжилсэн огноо:</el-col>
              <el-col :span="14" class="border_bottom">{{ generateNowDate() }}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <table>
            <tr>
              <th>№</th>
              <th>Бараа</th>
              <th>Тоо хэмжээ</th>
              <th>Үнэ</th>
            </tr>
            <tr>
              <th style="background-color:white;">1</th>
              <th style="background-color:white;">{{title}}</th>
              <th style="background-color:white;">1</th>
              <th style="background-color:white;">{{price}}</th>
            </tr>
          </table>
        </el-row>
          <el-row class="mt5">
            <el-col :span="7">
              <el-row class="mt5">
              <el-col :span="12"><strong>Нийт дүн:</strong></el-col>
              <el-col :span="12">{{price}}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt10">
            <el-col :span="24">
              <el-row>
                <el-col :span="8">ЕРӨНХИЙ ЗАХИРАЛ</el-col>
                <el-col :span="7">....................................</el-col>
                <el-col :span="8">/Ч. Долгорсүрэн/</el-col>
              </el-row>
              <el-row class="mt5">
                <el-col :span="8">ЕРӨНХИЙ НЯ-БО</el-col>
                <el-col :span="7">....................................</el-col>
                <el-col :span="8">/Д. Алтанцэцэг/</el-col>
              </el-row>
            </el-col>
          </el-row><br/>
          <el-row>
            <span>(Тамга)</span>
          </el-row>
          <el-row>
            <img :src="tamga" style="width: 120px; height: 110px; margin-top: 0;"/>
          </el-row>
      </el-row>
    </el-row>
    <div style="display:flex">
      <el-button class="buttons" @click="payment">Төлбөр төлөх</el-button>
      <el-button class="buttons" style="background-color: orange;" @click="download">Татах</el-button>
    </div>
  </div>
</template>
<script>
import { getBase64ImageFromURL } from '../../../../../helper/custom'
import tamga from '@/assets/tamga.png'
import dayjs from 'dayjs'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'Invoice',
  props: {
    price: Number,
    title: String,
    payment: Function
  },
  data () {
    return {
      loading: false,
      isCreate: false,
      isPayment: false,
      bannerData: null,
      types: [
        { name: 'Идэвхитэй' },
        { name: 'Идэвхигүй' }
      ],
      active: 'Идэвхитэй',
      services: [],
      serviceBanner: '',
      bannerImg: null,
      service: '',
      bannerListData: [],
      step: 'info',
      is_done: false,
      email: '',
      promotionData: null,
      tamga
    }
  },
  methods: {
    async download () {
      const docDefinition = {
        content: [
          {
            columns: [
              { text: 'НХМаягт Т-1', alignment: 'left', style: 'invoice_description' },
              { text: '', alignment: 'left', style: 'invoice_description' },
              { text: 'Сангийн сайдын 2017 оны 12 дугаар сарын 5-ны өдрийн 347 тоот тушаалын хавсралт', alignment: 'right', style: 'invoice_description' }
            ],
            columnGap: 10
          },
          { text: 'НЭХЭМЖЛЭХ № 18', alignment: 'center', style: 'invoice_header', margin: [0, 20, 0, 40] },
          {
            columns: [
              { text: 'Нэхэмжлэгч:', alignment: 'left', style: 'text_bold', margin: [0, 0, 0, 8] },
              { text: 'Төлөгч:', alignment: 'left', style: 'text_bold', margin: [0, 0, 0, 8] }
            ]
          },
          {
            columns: [
              { text: 'Байгууллагын нэр:', alignment: 'left', style: 'text_body' },
              { text: 'ТокТок ХХК', alignment: 'left', style: 'text_body' },
              { text: 'Байгууллагын нэр:', alignment: 'left', style: 'text_body' },
              { text: this.$root.outlet.company_name, alignment: 'left', style: 'text_body' }
            ]
          },
          {
            columns: [
              { text: 'НӨАТ төлөгчийн дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '6183352', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'НӨАТ төлөгчийн дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.$root.outlet.vat_regno, alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Утас, факс:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '345525', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Гэрээний дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Төлбөр хийх хугацаа:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.generateNowDate(), alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Нэхэмжилсэн огноо:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.generateNowDate(), alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Банкны нэр:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'ХААН ТӨВ БАНК', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Дансны дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '5173108943', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            layout: {
              hLineWidth: function (i, node) {
                return (i === i++) ? 1 : 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            },
            table: {
              headerRows: 1,
              widths: ['auto', '*', '*', '*'],
              body: [
                [{ text: '№', style: 'text_table_header' }, { text: 'Бараа', style: 'text_table_header' }, { text: 'Тоо хэмжээ', style: 'text_table_header' }, { text: 'Үнэ', style: 'text_table_header' }],
                [{ text: 1, style: 'fontSize: 9' }, { text: this.title, style: 'text_table_data' }, { text: 1, style: 'text_table_data' }, { text: this.$helpers.formatPrice(this.price), style: 'text_table_data' }]
              ]
            }
          },
          {
            columns: [
              { text: '', alignment: 'center', style: 'text_body' },
              { text: 'Нийт дүн:', alignment: 'right', style: 'text_body_bold', margin: [0, 5, 0, 0] },
              { text: this.$helpers.formatPrice(this.price), alignment: 'right', style: 'text_body', width: '15%', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '', alignment: 'left', style: 'text_body', width: '32.5%' },
              { text: 'ЕРӨНХИЙ ЗАХИРАЛ   ......................   /Ч. Долгорсүрэн/', alignment: 'left', style: 'text_body', margin: [0, 20, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '', alignment: 'left', style: 'text_body', width: '32.5%' },
              { text: 'ЕРӨНХИЙ НЯ-БО   ......................   /Д. Алтанцэцэг/', alignment: 'left', style: 'text_body' }
            ]
          },
          {
            columns: [
              { text: '(Тамга)', alignment: 'left', style: 'text_body', margin: [0, 10, 0, 0] }
            ]
          },
          {
            image: await getBase64ImageFromURL(this.tamga),
            width: 120,
            height: 110
          }
        ],
        styles: {
          invoice_description: {
            fontSize: 8
          },
          invoice_header: {
            fontSize: 12,
            bold: true
          },
          text_bold: {
            fontSize: 9,
            bold: true
          },
          text_body: {
            fontSize: 9
          },
          text_body_bold: {
            fontSize: 9,
            bold: true
          },
          text_table_header: {
            fontSize: 9,
            fillColor: '#e6d4b4',
            alignment: 'center'
          },
          text_table_data: {
            fontSize: 9,
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition).download('Нэхэмжлэх.pdf')
    },
    generateNowDate () {
      const today = dayjs().format('YYYY/MM/DD')
      return today
    }
  }
}
</script>
<style scoped>
.slide {
  display: flex;
  flex-direction: column;
  height: 83vh;
  width: 480px;
  background-color: #ffffff;
}
.submenu {
  background-color: rgb(244, 244, 244);
  border-radius: 6px;
  width: 90%;
  margin-left: 3%;
  cursor: pointer;
  padding-bottom: 5%;
}
.discountBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(244, 244, 244);
  color: rgb(78, 77, 77);
}
.button {
  width: 260px;
  color: black;
  font-weight: bold;
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  font-size: 12px;
  margin-bottom: 3%;
}
.buttons {
  width: 100%;
  color: white;
  background-color: #ffb500;
  border-radius: 8px;
  margin-top: 2%;
}
.buttonClose {
  width: 100%;
  color: black;
  background-color: #f5f4f2;
  border-radius: 8px;
  margin-top: 2%;
}
.listcss {
  width:82%;
  background-color:rgb(244, 244, 244);
  border-radius:15px;
  padding:4% 4% 4% 4%;
  margin-top:4%;
  max-height: 100%;
  overflow: auto;
}
.imageCss {
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
  width:100%;
  height:150px;
}
.invoice_body {
  font-size: 9px;
  width: 100%;
  margin: 20px auto;
}
.invoice_header {
  font-size: 12px;
  margin-top: 2%;
  margin-bottom: 5%;
}
.invoice_description {
  font-size: 8px;
}
.el-col .border_bottom {
  border-bottom: 1px dashed
}
table {
  width: 100%;
  border: 0.5px solid;
  border-collapse: collapse
}
th {
  border: 0.5px solid;
  padding: 3px;
  background-color: #e6d4b4;
}
td {
  text-align: right;
}
.el-button--warning:hover {
  background-color: #FFB500 !important;
  border-color: #FFB500 !important
}
</style>
