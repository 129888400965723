<template>
  <div>
    <el-drawer v-if="isShow" :visible="isShow" :size="size" @close="close">
      <div style="padding-left:10%; padding-right:30%;">
        <h1 style="text-align:center">И-Баримт</h1>
        <div style="margin-bottom:5%; display:flex; justify-content: space-between;">
          <b>ДДТД:</b>
          <span>{{ebarimtData.billId}}</span>
        </div>
        <div style="margin-bottom:5%; display:flex; justify-content: space-between;">
          <b>Он сар:</b>
          <span>{{ebarimtData.date}}</span>
        </div>
        <div v-if="ebarimtData.lottery" style="margin-bottom:5%; display:flex; justify-content: space-between;">
          <b>Сугалааны дугаар:</b>
          <span>{{ebarimtData.lottery}}</span>
        </div>
        <div style="margin-bottom:20%; display:flex; justify-content: space-between;">
          <b>Нийт дүн:</b>
          <span>{{ebarimtData.amount}}</span>
        </div>
        <div style="display: flex; justify-content: center;">
          <qr-code :text="ebarimtData.qrData"></qr-code>
        </div>
        <div>
          <p style="font-size:12px; text-align:center; color: grey">Лавлах зүйл байвал 7766-7766 дугаараас асууна уу</p>
        </div>
      </div>
    </el-drawer>
 </div>
</template>
<script>
export default {
  name: 'Ebarimt',
  props: {
    isShow: Boolean,
    ebarimtData: Object,
    close: Function
  },
  mounted () {
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      size: ''
    }
  },
  methods: {
    copy (type) {
      if (type === 'ddtd') {
        this.$refs.ddtd.focus()
        document.execCommand('copy')
      } else if (type === 'lottery') {
        this.$refs.lottery.focus()
        document.execCommand('copy')
      }
    }
  }
}
</script>
<style scoped>
.field {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 12px;
}
.copy {
  border: 1px solid;
  border-radius: 50px;
  background-color: #333333;
  color: #fff;
  font-size: 11px;
  padding: 5px 10px;
}
.result {
  border: 1px solid rgb(233, 232, 232);
  padding: 2% 4%;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btnClss {
  width: 50%;
  color: black;
  background-color: #ffb500;
  border-radius: 20px;
  padding-top: 3%;
  padding-left: 1%;
  padding-bottom: 4%;
}
</style>
