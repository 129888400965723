<template>
  <div style="background: #E6E6E6; height: 100%; padding: 50px 30px">
    <div style="display:flex; flex-direction: row; margin-bottom: 25px; justify-content: space-between; align-items: center">
      <div style="display:flex; flex-direction: row">
        <div style="width: 3px; height:30px; background: #FFB500; border-radius: 5px"/>
        <span style="font-size: 24px; font-weight: 700; color: black; margin-left: 5px">Салбарууд</span>
      </div>
      <el-button style="padding: 16px; border-radius: 12px; background: white" @click="showScheduleHistoryDialog()">Өөрчлөлтийн түүх</el-button>
    </div>
      <div class="header gridContainer" v-loading="loading" v-if="$root.chosenOutlets.length > 0">
        <div v-for="(outlet, index) in $root.chosenOutlets" :key="index">
          <div style="background: white; padding: 20px; gap: 20px; display: flex; flex-direction: column; border-radius: 12px">
            <div style="display: flex; flex-direction: row; justify-content: space-between">
              <span style="font-size: 16px; font-weight: 700; color: #3A3A3C">{{outlet.full_name_mon}}</span>
              <div style="display: flex; flex-direction: row; align-items: center">
                <span style="font-size: 12px; font-weight: 600; color: #A1A1AA; margin-right: 5px">{{outlet.is_new_order_send_sms === true ? 'Идэвхитэй' : 'Идэвхигүй'}}</span>
                <el-switch v-model="outlet.is_new_order_send_sms" @change="onChangeSwitch(outlet)"/>
              </div>
            </div>
            <div style="border-top: 1px solid #E6E6E6" />
            <div>
              <span style="font-size: 10px; font-weight: 600; color: #3A3A3C;">Мессеж хүлээн авах дугаар</span>
              <el-button v-if="Object.prototype.hasOwnProperty.call(outlet, 'new_order_sms_phone') && outlet.new_order_sms_phone === '' || !Object.prototype.hasOwnProperty.call(outlet, 'new_order_sms_phone')" style="background: #F8F8F8; font-size: 14px; font-weight: 600; color: black; border-radius: 10px; border: none; width: 100%; padding: 15px; margin-top: 5px" @click="onPressNumberRegister(outlet)">Дугаар бүртгүүлэх</el-button>
              <div v-else="outlet.new_order_sms_phone" @click="onPressNumberRegister(outlet)" style="cursor: pointer; display: flex; justify-content: space-between; align-items: center; background: #F8F8F8; padding: 15px; border-radius: 15px; margin-top: 5px">
                <span style="color: #231F20; font-size: 14px; font-weight: 600">{{outlet.new_order_sms_phone}}</span>
                <span style="padding: 5px 10px; background: #333333; color: white; font-size: 10px; border-radius: 40px">Дугаар өөрчлөх</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="Мэдээлэл шинэчлэх"
        style="text-align:center;"
        :visible.sync="isShowModal"
        width="400px"
        :before-close="closeModal">
        <div style="text-align: start; display:flex; flex-direction: column; gap: 14px">
          <div>
            <span style="color: #3a3a3c; font-size: 10px; font-weight: 600; margin-bottom: 5px">Үйлчилгээ авах салбар</span>
            <el-input v-model="outletData.full_name_mon" disabled />
          </div>
          <div>
            <span style="color: #3a3a3c; font-size: 10px; font-weight: 600; margin-bottom: 5px">Өөрчлөх дугаараа оруулна уу</span>
            <el-input placeholder="89112247" v-model="outletData.new_order_sms_phone" />
          </div>
          <el-button @click="sendData()" :disabled="Object.prototype.hasOwnProperty.call(outletData, 'new_order_sms_phone') && outletData.new_order_sms_phone === '' || !Object.prototype.hasOwnProperty.call(outletData, 'new_order_sms_phone') ? true : false" style="background: #FFB500; padding: 15px; border-radius: 12px; border: none; margin-top: 14px; color: black; font-size: 14px; font-weight: 600">Хадгалах</el-button>
        </div>
      </el-dialog>
    <sms-history
      :isShow="isShowDrawer"
      :closeFunction="closeDrawer"
      :historyData="historyData"
      :changePage="changePage"
      :currentPage="currentPage"
      :loading="historyLoading"
      :changeOutlet="changeOutlet"
      :selectedOutlet="selectedOutlet"
    />
  </div>
</template>
<script>
import TitleItem from '../../../../components/items/titleItem.vue'
import mAxios from '../../../../helper/axiosInstance'
import SmsHistory from './component/smsHistory.vue'
export default {
  name: 'packageService',
  components: {
    TitleItem,
    SmsHistory
  },
  data () {
    return {
      loading: false,
      outletData: {},
      isShowDrawer: false,
      historyData: {},
      isShowModal: false,
      currentPage: 1,
      historyLoading: false,
      selectedOutlet: this.$root.chosenOutlets[0]._id
    }
  },
  methods: {
    changePage (page) {
      this.currentPage = page
      this.showScheduleHistoryDialog()
    },
    changeOutlet (outlet) {
      this.currentPage = 1
      this.selectedOutlet = outlet
      this.showScheduleHistoryDialog()
    },
    closeDrawer () {
      this.isShowDrawer = false
    },
    closeModal () {
      this.isShowModal = false
      if (!this.$root.isAdmin) {
        this.$root.getChildOutlets()
      }
    },
    onPressNumberRegister (outlet) {
      outlet.is_new_order_send_sms = true
      this.outletData = outlet
      this.isShowModal = true
    },
    onChangeSwitch (outlet) {
      this.outletData = outlet
      if (Object.prototype.hasOwnProperty.call(outlet, 'is_new_order_send_sms') && outlet.is_new_order_send_sms === true) {
        this.isShowModal = true
      } else {
        this.sendData()
      }
    },
    async sendData () {
      try {
        const payload = {
          newOrderSmsPhone: this.outletData.new_order_sms_phone,
          isNewOrderSendSms: this.outletData.is_new_order_send_sms
        }
        const res = await mAxios.post(`outlet/${this.outletData._id}/updateSmsNumber`, payload)
        if (res.data.status == 'success') {
          this.$message({ message: res.data.data.message, type: 'success' })
          this.isShowModal = false
          if (!this.$root.isAdmin) {
            this.$root.getChildOutlets()
          }
        } else this.$message({ message: res.data.message, type: 'warning' })
      } catch (err) {
        console.log(err, 'updateSmsNumber error')
      }
    },
    async showScheduleHistoryDialog () {
      this.historyLoading = true
      try {
        const payload = {
          outlet_id: this.selectedOutlet,
          page: this.currentPage
        }
        const res = await mAxios.post('outlet/smsNumberHistory', payload)
        if (res.data.status == 'success') {
          this.historyLoading = false
          this.isShowDrawer = true
          this.historyData = res.data.data
          this.currentPage = res.data.data.current_page
        } else {
          this.historyLoading = false
          this.$message({ message: res.data.message, type: 'warning' })
        }
      } catch (err) {
        this.historyLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .gridContainer {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
