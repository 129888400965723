<template>
  <div style="max-width: 345px; padding: 30px">
    <div class="titleSection">
      <div class="yellowBorder"/>
      <span class="titleText">Мессеж үйлчилгээ</span>
    </div>
    <div class="descSection">
      <p class="descTitle">Kitchen программ дээр захиалга орох бүрд мессеж очих үйлчилгээ</p>
      <div class="descPriceSection">
        <span style="color: #7CBB00; font-size: 20px; font-weight: 700;">Үнэ: 100₮</span>
        <span style="font-size: 11px; font-weight: 400; color: #808080;">1  мессежийн үнэ</span>
      </div>
      <p class="descText">Нэг хэрэглэгчээс ирэх мессежний үнэ нь 100₮ ба нийт үнийн дүн харилцагч таны тооцооноос хасагдахийг анхаарна уу</p>
      <div style="border: 1px solid #E6E6E6" />
      <p class="activeSector">Идэвхитэй 0 салбар байна</p>
    </div>
  </div>
</template>
<script>

import TitleItem from '../../../../components/items/titleItem.vue'
import SmsHistory from './component/smsHistory.vue'
export default {
  name: 'packageService',
  components: {
    TitleItem,
    SmsHistory
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style>
.descSection {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px #E6E6E6 solid;
  border-radius: 12px;
  gap: 5px;
}
.descTitle {
  font-size: 16px;
  font-weight: 600;
  color: #231F20;
  margin-top: 0px;
}
.descPriceSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.descText {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
}
.titleSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}
.yellowBorder {
  width: 3px;
  height: 30px;
  border-radius: 5px;
  background: #FFB500;
}
.titleText {
  font-size: 24px;
  font-weight: 700;
  color: #3a3a3c;
}
.activeSector {
  color: #7CBB00;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
</style>
