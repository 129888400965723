<template>
  <div>
    <div style="display: flex" v-if="!isCreate">
      <div class="slide" v-loading="loading">
        <div
          style="
            display: flex;
            margin-right: 5%;
            justify-content: space-between;
            padding-right: 5%;
          "
        >
          <title-item title="Баннер байршуулах"></title-item>
          <el-button
            @click="create('home')"
            type="warning"
            class="addBtn"
            icon="el-icon-plus"
            circle
          ></el-button>
        </div>
        <div class="listcss">
          <div
            v-for="(banner, bannerIndex) in bannerListData"
            :key="bannerIndex"
            style="
              border-radius: 7%;
              border: 1px solid lightgrey;
              background-color: white;
              margin-bottom: 3%;
            "
          >
            <div v-if="banner.promotion_items[0].banner !== null">
              <img
                v-if="banner.promotion_items[0].banner.source_url"
                :src="banner.promotion_items[0].banner.source_url"
                class="imageCss"
              />
              <div v-else class="textClass">Ток токooр бэлдүүлэх</div>
              <div style="display: flex; justify-content: space-between">
                <span
                  v-if="banner.promotion_items[0].banner.page === 'home'"
                  style="
                    font-size: 15px;
                    color: black;
                    padding-top: 3%;
                    padding-left: 5%;
                  "
                  >Нүүр хуудас</span
                >
                <span
                  v-else-if="
                    banner.promotion_items[0].banner.page === 'restaurant'
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    padding-top: 3%;
                    padding-left: 5%;
                  "
                  >Категори</span
                >
                <span
                  style="
                    font-size: 15px;
                    color: black;
                    padding-top: 3%;
                    padding-right: 5%;
                  "
                  >{{ banner.promotion_items[0].item_price }}₮</span
                >
              </div>
              <span
                :style="
                  banner.pay_status === 'not_paid'
                    ? 'font-size: 15px; color:red; padding-left: 5%;'
                    : 'font-size: 15px; color:#7CBB00; padding-left: 5%;'
                "
              >
                {{
                  banner.pay_status === "not_paid"
                    ? "Төлбөр хүлээгдэж байгаа"
                    : "Төлөгдсөн"
                }}
              </span>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-left: 5%;
                "
              >
                <el-button
                  size="small"
                  class="buttons"
                  style="width: 60%"
                  @click="pay(banner.promotion_id, 'invoice')"
                >
                  Нэхэмжлэх
                </el-button>
                <el-button
                  v-loading="load"
                  v-if="banner.pay_status === 'not_paid'"
                  @click="pay(banner.promotion_id, '')"
                  size="small"
                  class="buttons"
                  style="width: 30%; background-color: #37d461"
                  >Төлөх</el-button
                >
                <el-button
                  v-loading="load"
                  v-else-if="banner.pay_status === 'paid'"
                  @click="ebarimt(banner.promotion_id)"
                  size="small"
                  class="buttons"
                  style="width: 30%; color: white; background-color: #1f417e"
                  >И-Баримт</el-button
                >
              </div>
              <div
                style="
                  margin-bottom: 5%;
                  display: flex;
                  align-items: center;
                  margin-left: 5%;
                  margin-right: 5%;
                "
              >
                <el-button
                  v-if="banner.pay_status === 'not_paid'"
                  size="small"
                  class="buttons"
                  style="width: 100%"
                  v-loading="checkLoad"
                  @click="checkPayment(banner.promotion_id)"
                  ><i class="el-icon-refresh"></i>Төлбөр шалгах</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="discountBody">
        <h1>Баннер байршуулах</h1>
        <p style="text-align: center">
          Баннер байршуулах хэсгээ сонгож шинээр Баннер үүсгэнэ үү
        </p>
        <div v-for="(type, typeIndex) in bannerType" :key="typeIndex">
          <el-button class="button" @click="create(type.promotion_product_id)">
            {{type.name_mon}}
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
    </div>
    <div v-else-if="isCreate" style="display: flex" v-loading="loading">
      <div
        style="
          border-radius: 3%;
          margin-left: 3%;
          display: flex;
          flex-direction: column;
          background-color: rgb(244, 244, 244);
          padding: 3% 3%;
        "
      >
        <el-select
          style="margin-bottom: 5%"
          @change="changeService"
          v-model="serviceBanner"
          :disabled="step === 'invoice'"
        >
          <el-option
            v-for="(service, serviceIndex) in services"
            :key="serviceIndex"
            :label="service.name"
            :value="service.id"
          />
        </el-select>
        <img :src="form.image" style="border-radius: 5%; height: 80%; position:relative" />
        <img :src="$root.outlet.img_url" class="bannerImgCss"/>
      </div>
      <div style="margin-left: 2%">
        <title-item title="Шинээр Баннер байршуулах" />
        <div
          style="
            display: flex;
            flex-direction: row;
            background-color: rgb(244, 244, 244);
            border-radius: 10px;
            margin-bottom: 5%;
            justify-content: space-between;
            padding: 4% 8%;
            font-size: 11px;
          "
        >
          <span :style="step === 'info' ? 'color:#1877F2;' : ''"
            >Мэдээлэл оруулах</span
          >
          <i class="el-icon-arrow-right"></i>
          <span :style="step === 'invoice' ? 'color:#1877F2;' : ''"
            >Нэхэмжлэх</span
          >
        </div>
        <div v-if="step === 'info'" style="width:400px;">
          <div style="display: flex">
            <el-upload
              v-if="!isTokTok"
              v-model="bannerImg"
              class="avatar-uploader"
              action=""
              fit="cover"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="(file) => uploadImage(file)"
            >
              <el-image
                v-if="bannerImg"
                :src="bannerImg"
                class="avatar"
                style="width: 400px; height: 200px;"
              />
              <span v-else>
                <i
                  class="el-icon-plus"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 400px;
                    height: 180px;
                  "
                  >&nbsp;Зураг оруулах</i
                >
                <div style="display:flex; justify-content:space-between; wrap-text:wrap; font-size:11px; color:grey; margin:2px 2%;">
                  <li>Зураг 1200х600pxl байх ёстой</li>
                  <li>Зураг 1MB-с бага байх ёстой</li>
                </div>
              </span>
            </el-upload>
          </div>
          <div style="display:flex;">
            <el-switch
              v-model="isTokTok"
              @change="changeTokTok"
              active-color="#13ce66"
              active-text="Ток Ток-р баннер бэлдүүлэх"
              style="margin-bottom: 2%; margin-top: 3%"
            />
            <el-popover
              style="font-size:10px;"
              placement="bottom"
              width="200"
              content="Ток Ток-р баннер бэлдүүлэх бол 50000₮ нэмж төлөх болохыг анхаарна уу!!!">
              <i class="el-icon-info" style="font-size:14px; margin-left:40%; margin-top:10%; padding-top:100%;" slot="reference"></i>
            </el-popover>
          </div>
          <header>Email</header>
          <el-input
            v-model="email"
            size="small"
            placeholder="marketing@gmail.com"
          />
          <header style="margin-top: 2%">Баннер нэр</header>
          <el-input
            v-model="title"
            disabled
            size="small"
          />
          <header style="margin-top: 2%">Салбар</header>
          <el-select
            v-model="outlet_ids"
            @change="changeOutlets(outlet_ids)"
            placeholder="Сонгох"
            class="dblock text"
            style="width: 100%"
            collapse-tags
            clearable
            multiple
            filterable
          >
            <el-option label="Бүх салбар" value="all"></el-option>
            <el-option
              v-for="(outlet, outletIndex) in $root.chosenOutlets"
              :label="outlet.full_name_mon"
              :value="outlet._id"
              :key="outletIndex"
            />
          </el-select>
          <header style="margin-top: 2%">Үйлчилгээ</header>
          <el-select
            v-model="service"
            placeholder="Сонгох"
            class="dblock text"
            style="width:100%;"
            filterable
            size="small"
          >
            <el-option
              v-for="service in $root.outlet.service_list"
              :label="service"
              :value="service"
              :key="service"
            />
          </el-select>
          <div style="display: flex; margin-top: 2%">
            <el-card
              style="
                border-radius: 15px;
                height: 80px;
                width: 50%;
                text-align: center;
                padding-bottom: 1%;
              "
            >
              <span style="font-size: 12px; color: #7cbb00"
                >Үргэлжлэх хугацаа<br
              /></span>
              <el-select
                v-model="dayOption"
                collapse-tags
                remote
                reserve-keyword
                value-key="price"
                @change="changeDayOption(dayOption)"
                placeholder="өдөр сонгох"
                size="small"
              >
                <el-option
                  v-for="(opt, optIndex) in form.options"
                  :key="optIndex"
                  :label="
                    (opt.duration_unit === 'week'
                      ? opt.duration_time * 7
                      : opt.duration_time) +
                    (opt.duration_unit === 'day'
                      ? ' өдөр'
                      : opt.duration_unit === 'week'
                      ? ' хоног'
                      : ' сар')
                  "
                  :value="{
                    price: opt.price,
                    duration_unit: opt.duration_unit,
                    duration_time: opt.duration_time,
                  }"
                />
              </el-select>
            </el-card>
            <el-card
              style="
                border-radius: 15px;
                height: 80px;
                width: 50%;
                margin-left: 2%;
                padding-bottom: 0;
              "
            >
              <span style="font-size: 12px; color: #7cbb00"
                >Үнийн мэдээлэл<br
              /></span>
              <b
                style="font-size: 18px; text-align: center"
                v-if="form && dayOption"
                >{{ form.price }}₮</b
              >
            </el-card>
          </div>
          <div style="display: flex">
            <el-button class="buttons" @click="createService()"
              >Оруулах</el-button
            >
            <el-button
              class="buttons"
              style="background-color: grey !important"
              @click="cancel()"
              >Болих</el-button
            >
          </div>
        </div>
        <div v-else-if="step === 'invoice'">
          <div>
            <div style="display: flex; justify-content: space-between">
              <span class="type">{{ $root.outlet.vat_type }}</span>
              <span class="type">{{ $root.outlet.vat_regno }}</span>
            </div>
            <el-divider></el-divider>
            <div>
              <invoice :price="form.price" :payment="payment" :title="form.name_mon" />
              <el-button
                @click="cancel"
                class="buttons"
                style="margin-left: 2%; background-color: #7cbb00"
                >Баннер жагсаалт</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment-info
      v-if="isPayment"
      :createInvoice="createPromotionInvoice"
      :selectedInvoice="selectedInvoice"
      :isShow="isPayment"
      :close="close"
      :done="done"
      :order="orderData"
    />
    <ebarimt
      v-if="isEbarimt"
      :isShow="isEbarimt"
      :ebarimtData="ebarimtData"
      :close="closeEbarimt"
    />
    <el-drawer
      v-if="isInvoice"
      title="Нэхэмжлэх"
      :visible="isInvoice"
      :size="size"
      @close="closeDialog"
    >
      <div style="display:flex; justify-content:center; margin-right:3%;">
        <invoice :price="form.price" :title="form.name_mon" :payment="payment" />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import titleItem from '../../../../components/items/titleItem.vue'
import mAxios from '../../../../helper/axiosInstance'
import { getBase64 } from '../../../../helper/custom'
import dayjs from 'dayjs'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import paymentInfo from './component/paymentInfo.vue'
import invoice from './component/invoice.vue'
import ebarimt from './component/ebarimt.vue'
import * as codeGeneretor from 'voucher-code-generator'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'Banner',
  components: {
    titleItem,
    paymentInfo,
    invoice,
    ebarimt
  },
  mounted () {
    this.generateCode()
    this.bannerList()
    this.bannerCreate()
    if (window.innerWidth < 600) {
      this.size = '95%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '65%'
    } else {
      this.size = '40%'
    }
    this.service = this.$root.outlet.service_list[0]
  },
  data () {
    return {
      loading: false,
      isCreate: false,
      isPayment: false,
      bannerData: null,
      types: [{ name: 'Идэвхитэй' }, { name: 'Идэвхигүй' }],
      active: 'Идэвхитэй',
      services: [],
      serviceBanner: '',
      form: {},
      bannerImg: null,
      title: '',
      service: '',
      bannerListData: [],
      step: 'info',
      email: '',
      promotionData: null,
      outlet_ids: [],
      orderRefer: '',
      checkLoad: false,
      isEbarimt: false,
      ebarimtData: null,
      orderData: null,
      load: false,
      isTokTok: false,
      dayOption: '',
      size: '',
      isInvoice: false,
      bannerType: [],
      selectedInvoice: null
    }
  },
  methods: {
    async createPromotionInvoice (name, id) {
      const body = {
        order_id: id,
        payment_name: name
      }
      await mAxios.post('promotion/createPromotionInvoice', body).then((response) => {
        if (response.data.status === 'success') {
          this.selectedInvoice = response.data.data
          this.$message.success({
            message: 'Амжилттай үүслээ',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: response.data.message
          })
        }
      })
    },
    async bannerList () {
      const payload = {
        size: 100,
        from: 0,
        outlet_id: this.$root.isAdmin ? this.$root.chosenOutlets[0]._id : this.$root.outlet._id,
        service_type: 'banner',
        status: ''
      }
      this.loading = true
      mAxios.post('/promotion/getAllPromotion', payload).then((response) => {
        if (response.data.status === 'success') {
          this.bannerListData = response.data.data.hits
          this.loading = false
        } else {
          this.$message.error({
            message: response.data.message
          })
          this.loading = false
        }
      })
    },
    changeOutlets (outlet) {
      if (outlet.length > 0) {
        outlet.filter(res => {
          if (res === 'all') {
            this.outlet_ids = []
            this.$root.chosenOutlets.map(outletRes => {
              this.outlet_ids.push(outletRes._id)
            })
          }
        })
      }
    },
    generateCode () {
      const code = codeGeneretor.generate({
        prefix: this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_eng : this.$root.outlet.full_name_eng + '-',
        postfix: '-' + dayjs().format('MM/DD'),
        length: 5,
        count: 1
      })[0].toUpperCase()
      this.title = code
    },
    async create (id) {
      this.isCreate = true
      const payload = {
        category_name: 'boost_banner'
      }
      this.services = []
      this.loading = true
      await mAxios
        .post('/promotion/getPromotionProductsByCategory', payload)
        .then((res) => {
          this.generateCode()
          this.bannerData = res.data.data.promotion_products
          res.data.data.promotion_products.filter(product => {
            if (product.promotion_product_id === id) {
              this.form = product
            }
          })
          this.serviceBanner = id
          res.data.data.promotion_products.map((response) => {
            this.services.push({
              name: response.name_mon,
              id: response.promotion_product_id
            })
          })
          this.loading = false
        })
    },
    async bannerCreate () {
      const payload = {
        category_name: 'boost_banner'
      }
      this.loading = true
      await mAxios
        .post('/promotion/getPromotionProductsByCategory', payload)
        .then((res) => {
          this.bannerType = res.data.data.promotion_products
          this.loading = false
        })
    },
    closeDialog () {
      this.isInvoice = false
    },
    changeDayOption (option) {
      this.form.price = this.isTokTok ? option.price + 50000 : option.price
      this.form.duration_time = option.duration_time
      this.form.duration_unit = option.duration_unit
    },
    changeTokTok () {
      if (this.isTokTok) {
        this.form.price = this.dayOption.price + 50000
      } else {
        this.form.price = this.dayOption.price
      }
    },
    close () {
      this.selectedInvoice = null
      this.isPayment = false
      this.bannerImg = null
      this.title = ''
    },
    closeEbarimt () {
      this.isEbarimt = false
    },
    done () {
      this.bannerList()
      this.isCreate = false
      this.isPayment = false
      this.isInvoice = false
    },
    changeService () {
      this.loading = true
      this.bannerData.map((response) => {
        if (response.promotion_product_id === this.serviceBanner) {
          this.form = response
          this.bannerImg = null
        }
      })
      this.loading = false
    },
    async uploadImage (file) {
      try {
        this.loading = true
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        const image = new Image()
        image.src = URL.createObjectURL(file.raw)
        const promise = new Promise((resolve) => {
          image.onload = function () {
            resolve({ width: image.width, height: image.height })
          }
        })
        const result = await promise
        if (result.width !== 1200 && result.height !== 600) {
          this.loading = false
          throw new Error('Зураг 1200х600px байх ёстой.')
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!isLt2M) throw new Error('Зураг 1MB-с бага байх ёстой.')
        await getBase64(file.raw).then(async (res) => {
          const payload = {
            base64: res
          }
          await axios
            .post('https://rest.toktok.mn/other/profileImageUploader', payload)
            .then((response) => {
              if (response.data.status === 'success') {
                this.bannerImg = response.data.data
                this.loading = false
              }
            })
        })
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    cancel () {
      this.email = ''
      this.title = ''
      this.outlet_ids = []
      this.service = []
      this.bannerImg = null
      this.step = 'info'
      this.isCreate = false
      this.dayOption = ''
      this.bannerList()
    },
    createService () {
      if (['', undefined, null].includes(this.dayOption)) {
        this.$message.error({
          message: 'Үргэлжлэх хугацаа сонгох ёстой',
          type: 'error'
        })
        return false
      }
      if (['', undefined, null].includes(this.email)) {
        this.$message.error({
          message: 'И-Мэйл оруулаагүй байна',
          type: 'error'
        })
        return false
      }
      if (this.outlet_ids.length < 1) {
        this.$message.error({
          message: 'Салбар сонгоогүй байна',
          type: 'error'
        })
        return false
      }
      if (['', undefined, null].includes(this.service)) {
        this.$message.error({
          message: 'Үйлчилгээ сонгоогүй байна',
          type: 'error'
        })
        return false
      }
      if (!this.isTokTok) {
        if (['', undefined, null].includes(this.bannerImg)) {
          this.$message.error({
            message: 'Зураг оруулаагүй байна',
            type: 'error'
          })
          return false
        }
      }
      this.loading = true
      const payload = {
        outlet_id: this.$root.isAdmin
          ? this.$root.chosenOutlets[0]._id
          : this.$root.outlet._id,
        outlet_name_mon: this.$root.isAdmin
          ? this.$root.chosenOutlets[0].full_name_mon
          : this.$root.outlet.full_name_mon,
        outlet_name_eng: this.$root.isAdmin
          ? this.$root.chosenOutlets[0].full_name_eng
          : this.$root.outlet.full_name_eng,
        service_type: 'banner',
        vat_type: this.$root.isAdmin
          ? this.$root.chosenOutlets[0].vat_type === 'company'
            ? 3
            : 1
          : this.$root.outlet.vat_type === 'company'
            ? 3
            : 1,
        vat_regno: this.$root.isAdmin
          ? this.$root.chosenOutlets[0].vat_type === 'company'
            ? this.$root.chosenOutlets[0].vat_regno
            : undefined
          : this.$root.outlet.vat_type === 'company'
            ? this.$root.outlet.vat_regno
            : undefined,
        vat_company_name: this.$root.isAdmin
          ? this.$root.chosenOutlets[0].company_name
          : this.$root.outlet.company_name,
        payment_name: 'qpay',
        email: this.email,
        promotion_items: [
          {
            duration_time: this.form.duration_time,
            duration_unit: this.form.duration_unit,
            promotion_product_name: this.form.name_mon,
            category_name: this.form.category_name,
            item_price: this.form.price,
            promotion_product_id: this.form.promotion_product_id,
            service_name: this.form.service_name,
            type: this.form.type,
            is_done: !this.isTokTok,
            content: {
              outlet_ids: this.outlet_ids,
              title: this.title,
              position: this.form.content.position,
              page: this.form.content.page,
              source_url: this.isTokTok ? null : this.bannerImg,
              service: this.service
            }
          }
        ]
      }
      mAxios.post('/promotion', payload).then((res) => {
        if (res.data.status === 'success') {
          this.promotionData = res.data.data
          this.orderRefer = res.data.data.order.order_refer
          this.orderData = res.data.data
          this.isPayment = true
          if (this.step === 'info') {
            this.step = 'invoice'
          }
          this.loading = false
        } else {
          this.$message.error({
            message: res.data.message,
            type: 'error'
          })
          this.loading = false
        }
      })
    },
    generateNowDate () {
      const today = dayjs().format('YYYY/MM/DD')
      return today
    },
    back () {
      this.step = 'info'
    },
    payment () {
      this.isPayment = true
    },
    async pay (id, type) {
      this.loading = true
      await mAxios.get('promotion/' + id).then((res) => {
        if (res.data.status === 'success') {
          this.orderRefer = res.data.data.order.order_refer
          this.orderData = res.data.data
          this.form.price = res.data.data.pay_total
          this.title = res.data.data.promotion_items[0].content.title
          this.form.name_mon = res.data.data.promotion_items[0].promotion_product_name
          if (type === 'invoice') {
            this.isInvoice = true
          } else {
            this.isPayment = true
          }
        } else {
          this.$message({
            message: 'Алдаа гарлаа',
            type: 'error'
          })
        }
        this.loading = false
      })
    },
    async ebarimt (id) {
      this.load = true
      await mAxios.get('promotion/' + id).then((res) => {
        if (res.data.status === 'success') {
          this.isEbarimt = true
          this.ebarimtData = res.data.data.order.ebarimt
        } else {
          this.$message({
            message: 'Алдаа гарлаа',
            type: 'error'
          })
        }
        this.load = false
      })
    },
    async checkPayment (id) {
      this.checkLoad = true
      await mAxios.get('promotion/' + id).then((res) => {
        if (res.data.status === 'success') {
          this.checkLoad = false
        }
        this.isCreate = false
        this.bannerList()
      })
    }
  }
}
</script>
<style scoped>
.slide {
  display: flex;
  flex-direction: column;
  height: 83vh;
  width: 480px;
  background-color: #ffffff;
}
.submenu {
  background-color: rgb(244, 244, 244);
  border-radius: 6px;
  width: 90%;
  margin-left: 3%;
  cursor: pointer;
  padding-bottom: 5%;
}
@media screen and (max-width: 600px) {
  .bannerImgCss {
    border-radius: 5%;
    height: 22%;
    width:92%;
    margin-top:80%;
    margin-left: -2%;
    position:absolute
  }
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
  .bannerImgCss {
    border-radius: 5%;
    height: 22%;
    width:92%;
    margin-top:70%;
    margin-left: -2%;
    position:absolute
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .bannerImgCss {
    border-radius: 5%;
    height: 20%;
    width:86%;
    margin-top: 70%;
    margin-left: -2%;
    position:absolute
  }
}
@media screen and (min-width: 1601px) {
  .bannerImgCss {
    border-radius: 5%;
    height: 23%;
    width:80%;
    margin-top:60%;
    margin-left: -2%;
    position:absolute;
  }
}
@media screen and (max-width: 900px) {
  .discountBody {
    display: none;
  }
  .discountBodyMobile {
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: rgb(244, 244, 244);
    color: rgb(78, 77, 77);
  }
  .btnCss {
    width: 80%;
    background-color: #ffb500;
    color: black;
  }
  .addBtn {
    width: 40px;
    height: 40px;
    margin-left: 10%;
  }
}
@media screen and (min-width: 901px) {
  .discountBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(244, 244, 244);
    color: rgb(78, 77, 77);
  }
  .discountBodyMobile {
    display: none;
  }
  .btnCss {
    display: none;
  }
  .addBtn {
    display: none;
  }
}
.button {
  width: 260px;
  color: black;
  font-weight: bold;
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  font-size: 12px;
  margin-bottom: 3%;
}
.buttons {
  width: 100%;
  color: white;
  background-color: #ffb500;
  border-radius: 8px;
  margin-top: 2%;
}
.buttonClose {
  width: 100%;
  color: black;
  background-color: #f5f4f2;
  border-radius: 8px;
  margin-top: 2%;
}
.listcss {
  width: 82%;
  background-color: rgb(244, 244, 244);
  border-radius: 15px;
  padding: 4% 4% 4% 4%;
  margin-top: 4%;
  max-height: 100%;
  overflow: auto;
}
.imageCss {
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
  width: 100%;
  height: 150px;
}
.textClass {
  width: 250px;
  height: 130px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px solid;
}
::-webkit-scrollbar {
  width: 0px;
}
.type {
  background-color: rgb(236, 234, 234);
  border-radius: 6px;
  width: 45%;
  padding-top: 2%;
  padding-left: 4%;
  padding-bottom: 2%;
}
.btnClss {
  width: 50%;
  color: black;
  background-color: #ffb500;
  border-radius: 20px;
  padding-top: 4%;
  padding-left: 2%;
  padding-bottom: 4%;
}
.invoice_body {
  font-size: 9px;
  width: 610px;
  margin: 20px auto;
}
.invoice_header {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.invoice_description {
  font-size: 8px;
}
.el-col .border_bottom {
  border-bottom: 1px dashed;
}
table {
  width: 100%;
  border: 0.5px solid;
  border-collapse: collapse;
}
th {
  border: 0.5px solid;
  padding: 3px;
  background-color: #e6d4b4;
}
td {
  text-align: right;
}
.el-button--warning:hover {
  background-color: #ffb500 !important;
  border-color: #ffb500 !important;
}
</style>
