<template>
  <div class="slide">
    <div class="submenu" :style="types === 'intro' ? 'color: #1877F2' : ''" @click="chooseSub('intro')">
      <i class="el-icon-s-opportunity" style="font-size:24px;"></i>
      <span style="font-size:12px; margin-left:3%;">Танилцуулга</span>
    </div>
    <div class="submenu" :style="types === 'story' ? 'color: #1877F2' : ''" @click="chooseSub('story')" style="justify-content:space-between;">
      <div style="display: flex; align-items: center;">
        <i class="el-icon-coin" style="font-size:24px;"></i>
        <span style="font-size:12px; margin-left:10%;">Стори</span>
      </div>
      <span style="background-color:#7cbb00; color:white; border-radius:10px; width:; padding: 3px 10px; font-size:10px; margin-right:10px;">
        Үнэгүй
      </span>
    </div>
    <div class="submenu" :style="types === 'package_promotion' ? 'color: #1877F2;justify-content:space-between' : 'justify-content:space-between'" @click="chooseSub('package_promotion')">
      <div style="display: flex; align-items: center;">
        <i class="el-icon-s-grid" style="font-size:24px;"></i>
        <span style="font-size:12px; margin-left:3%;">Багц үйлчилгээ</span>
      </div>
      <!-- <span style="background-color:#7cbb00; color:white; border-radius:10px; width:; padding: 3px 10px; font-size:10px; margin-right:10px;">
        70%
      </span> -->
    </div>
    <div class="submenu" :style="types === 'single_service' ? 'color: #1877F2' : ''" @click="chooseSub('single_service')">
      <i class="el-icon-document-add" style="font-size:24px"></i>
      <span style="font-size:12px; margin-left:3%">Дан үйлчилгээ</span>
    </div>
    <div class="submenu" :style="types === 'boost_banner' ? 'color: #1877F2' : ''" @click="chooseSub('boost_banner')">
      <i class="el-icon-picture" style="font-size:24px"></i>
      <span style="font-size:12px; margin-left:3%">Баннер байршуулах</span>
    </div>
    <div class="submenu" :style="types === 'new_order_sms' ? 'color: #1877F2' : ''" @click="chooseSub('new_order_sms')">
      <i class="el-icon-message" style="font-size:24px"></i>
      <span style="font-size:12px; margin-left:3%">Шинэ захиалга SMS авах</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addSales',
  props: [
    'chooseSub',
    'types'
  ]
}
</script>
<style scoped>
.slide {
  display: inline-block;
  height: 82vh;
  background-color: #E6E6E6;
  padding: 0 15px;
  min-width: 250px;
}
.submenu {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.submenu:hover {
  background-color: rgb(223, 218, 218);
}
</style>
