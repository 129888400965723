<template>
  <div class="single-service-container">
    <div v-loading="listLoading">
      <title-item title="Дан үйлчилгээ" class="mb20"/>
      <div class="single-service-left-container" v-if="singleServiceList.length > 0">
        <ul class="under-list-container" id="scroll-style">
          <li v-for="(item, itemIndex) in singleServiceList" :key="itemIndex">
            <singleServiceListItem :item="item" :getServices="singleServiceOutletList" :setData="setData" :checkPayment="checkPayment"/>
          </li>
        </ul>
      </div>
      <div v-else class="noService">Үйлчилгээ байхгүй байна...</div>
    </div>
    <div class="single-service-right-container" v-loading="loading">
      <div class="right-container-header">
        <span class="select-item-body-header">Дан үйлчилгээ сонгох</span>
        <span class="select-item-body-description">Бизнесээ сурталчилах нэмэлт үйлчилгээнүүдээс сонгож <br /> хэрэглэгчиддээ илүү ойртоорой.</span>
      </div>
      <div class="carouselContainer">
        <singleServiceItem :service="service" :handleFunction="handleOneService" v-for="(service, indexService) in singleServiceSelectData" :key="indexService"/>
      </div>
    </div>
    <el-drawer @close="closeDrawer" :visible="drawer" v-if="selectedService" :size="size">
      <div class="Drawer">
        <div class="detail-left-container">
          <div style="display: flex; flex-direction: column; gap: 30px">
            <title-item :title="selectedService.name_mon"/>
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span v-html="htmlContent"></span>
              <div class="detail-left-body" v-if="Object.prototype.hasOwnProperty.call(selectedService, 'options')">
                <div class="detail-left-container-price">Хугацаа сонгох</div>
                <select v-model="selectedOption" class="custom-select">
                  <option :value="option" v-for="(option, optionIndex) in selectedService.options" :key="optionIndex">{{option.duration_time + ' хоног'}}</option>
                </select>
              </div>
              <div class="detail-left-price-container" v-if="selectedOption !== null && selectedOption !== undefined && Object.prototype.hasOwnProperty.call(selectedOption, 'price')">
                <span class="detail-left-container-price">Үнэ</span>
                <span class="detail-left-price">{{$helpers.formatPrice(selectedOption.price)}}₮</span>
              </div>
              <div class="detail-left-price-container" v-if="Object.prototype.hasOwnProperty.call(selectedService, 'price')">
                <span class="detail-left-container-price">Үнэ</span>
                <span class="detail-left-price">{{$helpers.formatPrice(selectedService.price)}}₮</span>
              </div>
            </div>
          </div>
          <div class="detail-left-container-footer">
            <button class="cursor-pointer detail-left-footer-button" @click="createSingleService"><div class="spinner" v-show="createLoading"></div>Үйлчилгээ авах</button>
            <button class="cursor-pointer detail-left-footer-button1" @click="closeDrawer()">Буцах</button>
          </div>
        </div>
        <div class="detail-right-container">
          <img style="width: 280px" :src="selectedService.image" alt="">
          <div class="detail-right-container-description">Таны сонгосон үйлчилгээ аппликейшн  <br>дээр дараах байдлаар харагдана.</div>
        </div>
      </div>
    </el-drawer>
    <payment-info
      v-if="isPayment && finishData"
      :createInvoice="createPromotionInvoice"
      :selectedInvoice="selectedInvoice"
      :isShow="isPayment"
      :close="close"
      :done="done"
      :order="finishData" />
    <ebarimt
      v-if="barimt && ebarimtData"
      :isShow="barimt"
      :ebarimtData="ebarimtData"
      :close="closeEbarimt" />
    <el-drawer
      v-if="isInvoice && finishData"
      title="Нэхэмжлэх"
      :visible="isInvoice"
      @close="closeDialog"
    >
      <div style="display:flex; justify-content:center; margin-right:3%;">
        <invoice :price="finishData.pay_total" :title="finishData.promotion_items[0].promotion_product_name" :payment="payment" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
import singleServiceItem from './component/singleServiceItem.vue'
import singleServiceListItem from './component/singleServiceListItem.vue'
import titleItem from '../../../../components/items/titleItem.vue'
import paymentInfo from './component/paymentInfo.vue'
import invoice from './component/invoice.vue'
import ebarimt from './component/ebarimt.vue'
export default {
  name: 'SingleService',
  components: {
    singleServiceItem,
    singleServiceListItem,
    titleItem,
    paymentInfo,
    invoice,
    ebarimt
  },
  data () {
    return {
      singleServiceList: [],
      singleServiceSelectData: [],
      listLoading: false,
      createLoading: false,
      loading: false,
      selectedService: null,
      selectedOption: null,
      drawer: true,
      isPayment: false,
      isInvoice: false,
      barimt: false,
      ebarimtData: null,
      finishData: null,
      size: '',
      htmlContent: '',
      selectedInvoice: null
    }
  },
  mounted () {
    this.singleServiceOutletList()
    this.getSingleService()
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '40%'
    }
  },
  methods: {
    async createPromotionInvoice (name, id) {
      const body = {
        order_id: id,
        payment_name: name
      }
      await mAxios.post('promotion/createPromotionInvoice', body).then((response) => {
        if (response.data.status === 'success') {
          this.selectedInvoice = response.data.data
          this.singleServiceOutletList()
          this.$message.success({
            message: 'Амжилттай үүслээ',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: response.data.message
          })
        }
      })
    },

    closeEbarimt () {
      this.barimt = false
    },
    ebarimt () {
      this.barimt = true
    },
    setData (data) {
      this.finishData = data
    },

    closeDialog () {
      this.isInvoice = false
    },

    invoice () {
      this.isInvoice = true
    },

    payment () {
      this.isPayment = true
    },

    handleOneService (service) {
      let tempHtml = ''
      if ((service?.desc || null) !== null && service.desc.includes('font-size')) {
        tempHtml = service.desc.replace('font-size: 10px;', 'font-size: 14px;')
      } else if ((service?.desc || null) !== null && service.desc.includes('color: red')) {
        tempHtml = service.desc.replace('style=\'color: red\'', 'style=\'font-size: 14px; color: red\'')
      } else {
        tempHtml = ''
      }
      this.htmlContent = tempHtml
      this.selectedOption = null
      this.selectedService = service
      this.drawer = true
    },

    close () {
      this.selectedInvoice = null
      this.isPayment = false
      this.isInvoice = true
      this.singleServiceOutletList()
    },

    closeDrawer () {
      this.drawer = false
    },

    done () {
      this.isPayment = false
      this.isInvoice = true
    },

    async checkPayment (id, type) {
      await mAxios.get('promotion/' + id).then((response) => {
        if (response.data.status === 'success') {
          this.setData(response.data.data)
          if (type === 'ebarimt') {
            this.ebarimtData = response.data.data.order.ebarimt
            this.ebarimt()
          } else if (type === 'invoice') {
            this.invoice()
          } else {
            this.payment()
          }
        }
      })
    },

    generatePayload () {
      const payload = {
        outlet_id: this.$root.isAdmin ? this.$root.chosenOutlets[0]._id : this.$root.outlet._id,
        outlet_name_eng: this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_eng : this.$root.outlet.full_name_eng,
        outlet_name_mon: this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_mon : this.$root.outlet.full_name_mon,
        service_type: 'single_service',
        vat_type: this.$root.isAdmin ? this.$root.chosenOutlets[0].vat_type === 'company' ? 3 : 1 : this.$root.outlet.vat_type === 'company' ? 3 : 1,
        vat_regno: this.$root.isAdmin ? (this.$root.chosenOutlets[0].vat_type === 'company' ? this.$root.chosenOutlets[0].vat_regno : undefined) : this.$root.outlet.vat_type === 'company' ? this.$root.outlet.vat_regno : undefined,
        vat_company_name: this.$root.isAdmin ? this.$root.chosenOutlets[0].company_name : this.$root.outlet.company_name,
        payment_name: 'qpay',
        email: this.$root.outlet.email,
        promotion_items: [this.selectedOption !== null ? {
          duration_time: this.selectedOption.duration_time,
          duration_unit: this.selectedOption.duration_unit,
          promotion_product_name: this.selectedService.name_mon,
          category_name: this.selectedService.category_name,
          item_price: this.selectedOption !== null ? this.selectedOption.price : this.selectedService.price,
          promotion_product_id: this.selectedService.promotion_product_id,
          service_name: this.selectedService.service_name,
          type: 'single',
          is_done: false
        } : {
          promotion_product_name: this.selectedService.name_mon,
          category_name: this.selectedService.category_name,
          item_price: this.selectedService.price,
          promotion_product_id: this.selectedService.promotion_product_id,
          service_name: this.selectedService.service_name,
          type: 'single',
          is_done: false
        }]
      }
      return payload
    },

    async singleServiceOutletList () {
      const payload = {
        size: 100,
        from: 0,
        outlet_id: this.$root.isAdmin ? this.$root.chosenOutlets[0]._id : this.$root.outlet._id,
        service_type: 'single_service',
        status: ''
      }
      this.listLoading = true
      mAxios.post('/promotion/getAllPromotion', payload).then((response) => {
        if (response.data.status === 'success') {
          this.singleServiceList = response.data.data.hits
          this.listLoading = false
        } else {
          this.$message.error({
            message: response.data.message
          })
          this.listLoading = false
        }
      })
    },

    getSingleService () {
      this.loading = true
      const payload = {
        category_name: 'single_service'
      }
      mAxios.post('/promotion/getPromotionProductsByCategory', payload).then(response => {
        if (response.data.status === 'success') {
          this.singleServiceSelectData = response.data.data.promotion_products
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },

    async createSingleService () {
      this.createLoading = true
      const payload = this.generatePayload()
      await mAxios.post('/promotion', payload).then(res => {
        if (res.data.status === 'success') {
          this.finishData = res.data.data
          this.createLoading = false
          this.drawer = false
          this.isPayment = true
          this.$message.success({
            message: 'Амжилттай үүслээ',
            type: 'success'
          })
          this.singleServiceOutletList()
        } else {
          this.$message.error({
            message: res.data.message,
            type: 'error'
          })
          this.createLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.custom-select {
  cursor: pointer;
  appearance: none; /* Reset default styles */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  padding: 5px; /* Add padding for better spacing */
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
  padding-right: 20px;
  color: #3A3A3C;
  font-size: 14px;
  font-weight: 600;
  background-color: white; /* Set background color */
  background-repeat: no-repeat; /* Prevent repeating */
  background-position: right center;
  background-image: url('../../../../assets/fi-br-caret-down.png');
}
.custom-select option {
  color: gray;
}
#scroll-style::-webkit-scrollbar-track
{
  border-start-end-radius: 12px;
  border-end-end-radius: 12px;
	background-color: #F8F8F8;
}

#scroll-style::-webkit-scrollbar
{
	width: 5px;
	background-color: #F8F8F8;
}

#scroll-style::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #32CE56;
}
.spinner {
  width: 8px;
  height: 8px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #32CE56;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
