<template>
  <div>
    <title-item title="Стори"/>
    <div
      style="width:88%; padding:1%; margin-left:2%; background-color:white; margin-bottom: 2%"
    >
      <el-select v-model="status" @change="getAllStories">
        <el-option
          v-for="(status, index) in storyStatus"
          :key="index"
          :label="status.name"
          :value="status.name"
        />
      </el-select>
      <el-select v-model="type" @change="getAllStories">
        <el-option
          v-for="(type, index) in storyType"
          :key="index"
          :label="type.name"
          :value="type.name"
        />
      </el-select>
    </div>
    <div style="width:90%; margin-left:2%;">
      <el-card v-loading="loading">
        <div style="display:flex; flex-direction: row; flex-wrap: wrap;">
          <div v-for="x in storyData" :key="x" style="margin-left:3%; display:flex; flex-direction: column; justify-content:center; align-items:center">
            <img :src="x.story_url" width="80px" style="height:80px; vertical-align: middle; border-radius: 50%;"/>
            <div>
              <span>{{x.story_name}}</span>
              <el-dropdown style="margin-top:10px; margin-left:12px;">
                <i class="el-icon-edit"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><el-button type="text" icon="el-icon-edit" @click="edit(x)">Edit</el-button></el-dropdown-item>
                  <el-dropdown-item><el-button type="text" icon="el-icon-delete" @click="deleteStory(x)">Delete</el-button></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-if="type === 'story'" @click="addStory" style="margin-left:2%;">
            <el-avatar class="cursor-pointer" :size="80">
              <i class="el-icon-plus">Story</i>
            </el-avatar>
          </div>
          <div v-else @click="addHighlight" style="margin-left:2%;">
            <el-avatar class="cursor-pointer" :size="80">
              <i class="el-icon-plus">highlight</i>
            </el-avatar>
          </div>
        </div>
      </el-card>
    </div>
    <add-highlight
      v-if="isAddHighlight"
      :isShow="isAddHighlight"
      :type="type"
      :storyData="storyData"
      :closeFunction="closeStory"></add-highlight>
    <add-story
      v-if="isAddstory"
      :isShow="isAddstory"
      :closeFunction="closeStory"
    ></add-story>
    <edit-story
      v-if="isEditStory"
      :isShow="isEditStory"
      :storyId="storyId"
      :type="type"
      :closeFunction="closeEditStory"
    ></edit-story>
  </div>
</template>
<script>
import mAxios from '../../../../helper/axiosInstance'
import addHighlight from './component/addHighlight.vue'
import addStory from './component/addStory.vue'
import editStory from './component/editStory.vue'
import TitleItem from '../../../../components/items/titleItem.vue'
export default {
  name: 'Story',
  components: {
    addStory,
    editStory,
    addHighlight,
    TitleItem
  },
  created () {
    this.getAllStories()
  },
  data () {
    return {
      loading: false,
      storyStatus: [{ name: 'active' }, { name: 'expired' }],
      storyType: [{ name: 'story' }, { name: 'highlight' }],
      storyData: [],
      storyId: '',
      isAddstory: false,
      isAddHighlight: false,
      isEditStory: false,
      status: 'active',
      type: 'story',
      outletIds: []
    }
  },
  methods: {
    async getAllStories () {
      if (this.$root.isAdmin) {
        this.outletIds = []
        this.$root.chosenOutlets.map(res => {
          this.outletIds.push(res._id)
        })
      }
      const payload = {}
      payload.size = 10
      payload.from = 0
      payload.outlet_ids = this.$root.isAdmin ? this.outletIds : this.$root.outletIds
      payload.status = this.status
      payload.type = this.type
      this.loading = true
      await mAxios.post('story/getAllStories', payload).then(res => {
        this.storyData = res.data.data
        this.loading = false
      })
    },
    addStory () {
      this.isAddstory = true
    },
    addHighlight () {
      this.isAddHighlight = true
    },
    edit (data) {
      this.storyId = data.story_id
      this.isEditStory = true
    },
    async deleteStory (data) {
      this.loading = true
      this.$confirm('Устгах гэж байна шүү?', 'Анхааруулга', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      })
        .then(async () => {
          await mAxios.post('story/deleteStory', { story_id: data.story_id }).then(res => {
            if (res.data.status === 'success') {
              this.getAllStories()
              this.loading = false
            } else {
              this.$message({
                message: res.data.message,
                type: 'warning'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Delete canceled'
          })
        })
    },
    closeStory () {
      this.getAllStories()
      this.isAddstory = false
      this.isAddHighlight = false
    },
    closeEditStory () {
      this.getAllStories()
      this.isEditStory = false
    }
  }
}
</script>
