<template>
  <div class="bodyCss">
    <sub-sidebar class="submenuCss" :chooseSub="chooseSub" :types="types"/>
    <div class="subMenuSelect">
      <el-select style="width: 90%;" v-model="menu" @change="chooseSub(menu)">
        <el-option
          v-for="(menu, menuIndex) in subMenuList"
          :key="menuIndex"
          :label="menu.name"
          :value="menu.id"
        />
      </el-select>
    </div>
    <sms-service v-if="types == 'new_order_sms'" />
    <div v-if="types === 'intro'" style="width:100%; margin-left:20px;">
      <title-item title="Борлуулалт өсгөх боломж"/>
      <div class="cardBody">
        <el-card v-for="(intro, introIndex) in secondData" :key="introIndex" style="width:300px; margin-left:2%;">
          <img v-if="intro.category_name_eng === 'boost_banner' || intro.category_name_eng === 'single_service'" src="@/assets/banner.png" style="border-radius:5%; width:100%; height:200px;">
          <img v-else-if="intro.category_name_eng === 'package_promotion'" src="@/assets/package.png" style="border-radius:5%; width:100%; height:200px;">
          <img v-else-if="intro.category_name_eng === 'user_based_promotion'" src="@/assets/user.png" style="border-radius:5%; width:100%; height:200px;">
          <img v-else-if="intro.category_name_eng === 'discount'" src="@/assets/discount.png" style="border-radius:5%; width:100%; height:200px;">
          <div style="padding-top: 10px; display:flex; justify-content:center;">
            <header style="font-weight:bold; font-size:18px; height:50px; text-align:center;">{{intro.category_name_mon}}</header>
          </div>
          <div>
            <span class="text" v-for="(data, dataIndex) in intro.datas" :key="dataIndex">
              {{data.name_mon}}
              <i class="el-icon-question" style="font-size:16px; color:grey; padding-right:12px"></i>
            </span>
            <el-row type="flex" justify="end" v-if="intro.datas.length > 3">
              <el-button type="text" @click="setMore(intro)" class="cursor-pointer" style="font-size: 12px">{{!intro.isMore ? '... цааш үзэх' : '... эвхэх'}}<i :class="intro.isMore ? 'el-icon-arrow-up' : 'el-icon-arrow-right'" /></el-button>
            </el-row>
          </div>
          <el-divider></el-divider>
          <el-button class="button" @click="chooseSub(intro.category_name_eng)"><i class="el-icon-plus"></i>Үйлчилгээ авах</el-button>
        </el-card>
      </div>
    </div>
    <div v-else style="width:100%; margin-left:20px;">
      <story v-if="types === 'story'" />
      <package-service v-else-if="types === 'package_promotion'" />
      <discount v-else-if="types === 'discount'" />
      <user-based-activation v-else-if="types === 'user_based_promotion'" />
      <banner v-else-if="types === 'boost_banner'" />
      <newOrderSms v-else-if="types === 'new_order_sms'" style="margin-left: -20px" />
      <singleService v-else />
    </div>
  </div>
</template>
<script>
import mAxios from '../../../helper/axiosInstance'
import packageService from './components/packageService.vue'
import discount from './components/discount.vue'
import userBasedActivation from './components/userBasedActivation.vue'
import story from './components/story.vue'
import banner from './components/banner.vue'
import newOrderSms from './components/newOrderSms.vue'
import TitleItem from '../../../components/items/titleItem.vue'
import subSidebar from './components/subSidebar.vue'
import smsService from './components/smsService.vue'
import singleService from './components/singleService.vue'
export default {
  name: 'addSales',
  components: {
    packageService,
    discount,
    userBasedActivation,
    story,
    banner,
    newOrderSms,
    TitleItem,
    subSidebar,
    smsService,
    singleService
  },
  async created () {
    await this.getData()
  },
  data () {
    return {
      allData: {},
      secondData: null,
      more: false,
      types: 'intro',
      menu: 'intro',
      subMenuList: [
        {
          id: 'intro',
          name: 'Танилцуулга'
        },
        {
          id: 'story',
          name: 'Стори'
        },
        {
          id: 'package_promotion',
          name: 'Багц үйлчилгээ'
        },
        {
          id: 'single_service',
          name: 'Дан үйлчилгээ'
        },
        {
          id: 'boost_banner',
          name: 'Баннер байршуулах'
        },
        {
          id: 'new_order_sms',
          name: 'SMS үйлчилгээ'
        }
      ]
    }
  },
  methods: {
    async getData () {
      await mAxios.get('/promotion/getAllPromotionTypes').then(res => {
        this.allData = Object.entries(res.data.data).map(([name, data]) => ({ name, ...data }))
        this.secondData = Object.entries(res.data.data).map(([name, data]) => ({ name, ...data }))
        if (this.secondData !== {}) {
          this.secondData.forEach(element => {
            element.isMore = false
            element.datas = element.datas.slice(0, 4)
          })
        }
      })
    },
    setMore (data) {
      this.allData.forEach(element => {
        if (element.category_name_mon === data.category_name_mon) {
          this.secondData.forEach(el => {
            if (el.category_name_mon === element.category_name_mon && el.isMore === false) {
              el.isMore = true
              el.datas = element.datas
            } else if (el.category_name_mon === element.category_name_mon && el.isMore === true) {
              el.isMore = false
              el.datas = el.datas.slice(0, 4)
            }
          })
        }
      })
    },
    chooseSub (type) {
      this.types = type
    }
  }
}
</script>
<style scoped>
.cardBody {
  margin-top:1%;
  display:flex;
  flex-wrap:wrap
}
.text {
  justify-content: space-between;
  font-size: 12px;
  display: flex;
  border: 1px solid #d6d4d4;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
}
.button {
  width: 100%;
  color: black;
  background-color: #ffb500;
  border-radius: 8px;
}
@media screen and (max-width: 600px) {
  .submenuCss {
    display: none;
  }
  .bodyCss {
    flex-direction: column;
  }
  .subMenuSelect {
    width: 90%;
    margin: 5% 5%;
    margin-right: 5%;
  }
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
  .bodyCss {
    display: flex;
  }
  .subMenuSelect {
    display: none;
  }
}
@media screen and (min-width: 1201px) {
  .bodyCss {
    display: flex;
  }
  .subMenuSelect {
    display: none;
  }
}
</style>
