<template>
  <div>
    <el-drawer
      :title="'Стори нэмэх'"
      :visible="isShow"
      @close="onClose"
      :close-on-click-modal="closeModal"
      :size="size"
      style="align-items: center; height: 100%"
    >
      <div class="warn">
        <div style="background-color:red; width:2%; border-top-left-radius: 0.5rem; border-bottom-left-radius: 0.5rem;"></div>
        <i class="el-icon-warning" style="font-size: 38px; color: #e62222; display:flex; align-items:center; padding-left:2%;"/>
        <div class="ml20">
          <p>1. Утасны дугаарын мэдээлэл оруулахгүй.</p>
          <p>2. Өөрсдийн хүргэлт, урамшуулалын мэдээлэл оруулж болохгүй.</p>
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="120px" class="demo-ruleForm">
        <el-form-item>
          <el-upload
            v-model="form.story_url"
            class="avatar-uploader"
            action=""
            fit="cover"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="file => uploadImage(file)"
          >
            <el-image
              v-if="form.story_url"
              :src="form.story_url"
              class="avatar"
              style="width:150px; height:150px;"
            />
            <span v-else>
              <i class="el-icon-plus" style="display:flex; align-items:center; justify-content:center; width:150px; height:150px;">Зураг оруулах</i>
            </span>
          </el-upload>
        </el-form-item>
        <el-form-item label="Стори нэр">
          <el-input v-model="form.name" style="width:50%"/>
        </el-form-item>
        <el-form-item label="Салбар сонгох">
          <el-select v-model="form.outlets" multiple filterable style="width:50%">
            <el-option
              v-for="(outlet, index) in $root.chosenOutlets"
              :key="index"
              :label="outlet.full_name_mon"
              :value="outlet._id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="create" type="success">Үүсгэх</el-button>
          <el-button @click="closeFunction">Хаах</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import { getBase64 } from '../../../../../helper/custom'
export default {
  name: 'Story',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  created () {
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      form: {
        name: '',
        story_url: '',
        outlets: []
      },
      loading: false,
      closeModal: false,
      size: ''
    }
  },
  methods: {
    onClose () {
      this.closeFunction()
    },
    async create () {
      const payload = {}
      payload.partner_id = this.form.outlets
      payload.route = 'HomeScreen'
      payload.story_name = this.form.name
      payload.story_url = this.form.story_url
      payload.created_partner_id = this.$root.outlet_id
      payload.type = 'story'
      await mAxios.post('story/addStory', payload).then(response => {
        if (response.data.status === 'success') {
          this.$message.success({
            message: 'Амжилттай үүслээ...',
            type: 'success'
          })
          this.onClose()
        } else {
          this.$message.warning({
            message: response.data.message,
            type: 'warning'
          })
        }
      })
    },
    async uploadImage (file) {
      try {
        this.loading = true
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!isLt2M) throw new Error('Зураг 1MB-с бага байх ёстой.')
        await getBase64(file.raw).then(res => {
          mAxios
            .post('other/uploadStory', {
              base64: res
            })
            .then(response => {
              if (response.data.status === 'success') {
                this.form.story_url = response.data.data
                this.loading = false
              }
            })
        })
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    }
  }
}
</script>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 16px;
    color: #8c939d;
    width: 250px;
    line-height: 150px;
    text-align: center;
  }
  .warn {
    display: flex;
    border: 0px solid #8c939d;
    box-shadow: inset 2px 2px 4px 5px rgb(0 0 0 / 0.05);
    margin: 0px 2%;
    border-radius: 12px;
    margin-bottom: 2%;
  }
</style>
