<template>
  <div>
    <el-drawer
      :title="'Create Highlight'"
      :visible="isShow"
      @close="onClose"
      :size="size"
      style="height: 100%"
    >
      <el-form label-width="40px">
        <el-form-item>
          <el-select placeholder="Салбар" v-model="form.outlets" multiple filterable style="width:60%; margin-bottom:1%;">
            <el-option
              v-for="(outlet, index) in $root.chosenOutlets"
              :key="index"
              :label="outlet.full_name_mon"
              :value="outlet._id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.name" style="width:60%; margin-bottom:1%;" placeholder="Highlight name"/>
        </el-form-item>
        <el-form-item>
          <el-upload
            v-model="form.story_url"
            class="avatar-uploader"
            action=""
            fit="cover"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="file => uploadImage(file)"
          >
            <el-image
              v-if="form.story_url"
              :src="form.story_url"
              class="avatar"
              style="width:150px; height:150px;"
            />
            <h1 v-else>
              <i slot="default" class="el-icon-plus avatar-uploader-icon">
                Зураг оруулах
              </i>
            </h1>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-card>
            <div style="display:flex; flex-direction: row; flex-wrap: wrap;">
              <div v-for="x in storyData" :key="x" :class="x.isChecked ? 'activeStory' : 'story'">
                <el-checkbox v-model="x.isChecked" @change="checkStory(x)" class="check"></el-checkbox>
                <img :src="x.story_url" width="70px" style="height:70px; vertical-align: middle; border-radius: 50%;"/>
                <div style="text-align:center;">
                  <span>{{x.story_name}}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-form-item>
        <el-form-item>
          <el-button @click="create" type="success">Үүсгэх</el-button>
          <el-button @click="closeFunction">Хаах</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import { getBase64 } from '../../../../../helper/custom'
export default {
  name: 'Story',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  created () {
    this.getAllStories()
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      form: {
        name: '',
        outlets: [],
        story_url: ''
      },
      childIds: [],
      loading: false,
      closeModal: false,
      storyData: [],
      storiesImg: [],
      size: ''
    }
  },
  methods: {
    onClose () {
      this.closeFunction()
    },
    async getAllStories () {
      const payload = {}
      payload.size = 10
      payload.from = 0
      payload.outlet_ids = this.$root.outletIds
      payload.status = 'expired'
      payload.type = 'story'
      this.loading = true
      await mAxios.post('story/getAllStories', payload).then(res => {
        this.storyData = res.data.data
        this.loading = false
      })
    },
    async create () {
      const payload = {}
      payload.partner_id = this.form.outlets
      payload.route = 'HomeScreen'
      payload.story_name = this.form.name
      payload.created_partner_id = this.$root.outlet_id
      payload.type = 'highlight'
      payload.child_id = this.childIds
      payload.story_url = this.form.story_url === '' ? this.storiesImg[0] : this.form.story_url
      await mAxios.post('story/addHighlight', payload).then(response => {
        if (response.data.status === 'success') {
          this.$message.success({
            message: 'Амжилттай үүслээ...',
            type: 'success'
          })
          this.onClose()
        } else {
          this.$message.warning({
            message: response.data.message,
            type: 'warning'
          })
        }
      })
    },
    checkStory (data) {
      if (data.isChecked) {
        if (this.childIds.length > 0) {
          this.childIds.map(res => {
            if (data.story_id !== res) {
              this.childIds.push(data.story_id)
              this.storiesImg.push(data.story_url)
            }
          })
        } else {
          this.childIds.push(data.story_id)
          this.storiesImg.push(data.story_url)
        }
      }
    },
    async uploadImage (file) {
      try {
        this.loading = true
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        await getBase64(file.raw).then(res => {
          mAxios
            .post('other/uploadStory', {
              base64: res
            })
            .then(response => {
              if (response.data.status === 'success') {
                this.form.story_url = response.data.data
                this.loading = false
              }
            })
        })
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    }
  }
}
</script>
<style>
.activeStory {
  margin-left:3%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}
.story {
  margin-left:3%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}
.check {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  margin-left:60%;
}
</style>
