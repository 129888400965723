<template>
  <div>
    <el-drawer v-if="isShow" :visible="isShow" :size="size" @close="close">
      <ul class="underList" v-if="payTypes.length > 0">
        <li class="listItem" style="border: 1px solid #7CBB00; color: #7CBB00" @click="setPaymentName(type.payment_name, order.order.id)" v-for="(type, typeIndex) in payTypes" :key="typeIndex">
          <a>
            <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
              <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 20px">
                <img
                  style="width: 20px; height: 20px;"
                  :src="type.image_url"
                  loading="lazy"
                  alt=""
                />
                <span class="uppercase" style="color: #7CBB00">{{ type.payment_name }}</span>
              </div>
              <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0948 2.61574C19.4051 1.91581 18.5848 1.35799 17.6803 0.973877C17.5217 0.904891 17.363 0.835905 17.1905 0.773817L16.6869 0.601353L16.1695 0.449586H16.0454C15.8729 0.401296 15.6935 0.359904 15.5211 0.325411H15.4314L14.914 0.228831H14.776L14.2793 0.159844C14.2128 0.152713 14.1458 0.152713 14.0793 0.159844L13.6309 0.0011765H13.3618H12.9341C12.8099 0.0011765 12.672 0.0011765 12.5409 0.0011765H12.2098H11.444C3.10362 -0.0747079 0.295898 3.51946 0.295898 11.0389V11.8805C0.295898 12.0116 0.295898 12.1358 0.295898 12.26V12.6877C0.295898 12.8463 0.295898 12.9981 0.295898 13.1499C0.295898 13.3017 0.295898 13.3499 0.295898 13.4396C0.295898 13.5293 0.295898 13.7845 0.344189 13.957C0.344189 14.0191 0.344189 14.095 0.344189 14.1571C0.344189 14.3433 0.38558 14.5158 0.406276 14.6952C0.406276 14.7435 0.406276 14.7917 0.406276 14.8331L0.489059 15.3781C0.489059 15.3781 0.489059 15.4402 0.489059 15.4747L0.592538 16.0128C0.595886 16.0334 0.595886 16.0543 0.592538 16.0749C0.633929 16.2542 0.675321 16.4336 0.723611 16.6061C0.771901 16.7854 0.820191 16.951 0.868481 17.1166C0.92367 17.289 0.978858 17.4477 1.04095 17.6064C1.10303 17.765 1.16512 17.9168 1.23411 18.0686C1.5723 18.8178 2.05796 19.4911 2.66211 20.0485C3.62739 20.9368 4.82237 21.5366 6.11141 21.78C7.86427 22.1288 9.65049 22.2815 11.4371 22.2353C19.7154 22.2353 22.5921 20.3865 22.5921 11.0596C22.5921 7.25159 21.9023 4.45076 20.0879 2.61574H20.0948ZM16.6455 10.5215L11.1267 14.8883C10.7616 15.178 10.3095 15.336 9.84354 15.3367C9.29484 15.3362 8.76879 15.1179 8.38104 14.7297L6.04242 12.4186C5.65454 12.0298 5.437 11.5029 5.43764 10.9537C5.43829 10.4045 5.65708 9.87806 6.04587 9.49018C6.43466 9.1023 6.96162 8.88476 7.51081 8.8854C8.06 8.88605 8.58644 9.10483 8.97432 9.49363L10.0022 10.5215L14.1 7.29298C14.5308 6.95267 15.0793 6.79747 15.6246 6.86151C16.1699 6.92555 16.6674 7.20359 17.0077 7.63446C17.348 8.06534 17.5032 8.61375 17.4392 9.15906C17.3752 9.70437 17.0971 10.2019 16.6662 10.5422L16.6455 10.5215Z"
                    :fill="selectedPaymentName === type.payment_name ? '#7CBB00' : '#E6E6E6'"
                  />
                </svg>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%" v-if="selectedInvoice !== null ? true : Object.prototype.hasOwnProperty.call(order, 'invoice')">
        <img
          :src="`https://chart.googleapis.com/chart?chs=220x220&cht=qr&chl=${selectedInvoice !== null ? selectedInvoice.qr_code : order?.invoice?.qr_code}&choe=UTF-8`"
          loading="lazy"
        />
      </div>
      <div style="padding-left:20%; padding-right:20%;" v-if="selectedPaymentName === 'ebank'" :class="Object.prototype.hasOwnProperty.call(order, 'invoice') && selectedPaymentName === 'ebank' ? '' : 'mt20'">
        <h1 style="text-align:center" class="uppercase text-bold">Дансаар төлбөр төлөх</h1>
        <div style="margin-bottom:5%;">
          <span>Хүлээн авагчийн данс</span>
          <div class="result">
            <input class="field" v-on:focus="$event.target.select()" ref="dans" readonly value="5173108943" />
            <el-button class="copy" @click="copy('dans')">
              <i class="el-icon-copy-document"></i>
              Хуулах
            </el-button>
          </div>
        </div>
        <div style="margin-bottom:5%;">
          <span>Шилжүүлэх үнийн дүн</span>
          <div class="result">
            <input class="field" v-on:focus="$event.target.select()" ref="price" readonly :value="order.pay_total" />
            <el-button class="copy" @click="copy('price')">
              <i class="el-icon-copy-document"></i>
              Хуулах
            </el-button>
          </div>
        </div>
        <div style="margin-bottom:5%;">
          <span>Хүлээн авагч</span>
          <div class="result">
            <input class="field" v-on:focus="$event.target.select()" ref="name" readonly value="Ток ток" />
            <el-button class="copy" @click="copy('name')">
              <i class="el-icon-copy-document"></i>
              Хуулах
            </el-button>
          </div>
        </div>
        <div style="margin-bottom:5%;">
          <span>Гүйлгээний утга</span>
          <div class="result">
            <input class="field" v-on:focus="$event.target.select()" ref="value" readonly :value="'PROMO-' + order.order.order_refer" />
            <el-button class="copy" @click="copy('value')">
              <i class="el-icon-copy-document"></i>
              Хуулах
            </el-button>
          </div>
          <div style="text-align: center;">
            <span style="font-size: 0.6vw; color: red;">Гүйлгээний утгыг буруу хийсэн тохиолдолд баталгаажихгүй болно.</span>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button class="btnClss" @click="done()"><i class="el-icon-refresh" style="font-size:20px; margin-right:5%;"></i>Болсон</el-button>
        </el-row>
      </div>
    </el-drawer>
 </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance.js'
export default {
  name: 'Payment Information',
  props: {
    createInvoice: Function,
    selectedInvoice: Object,
    isShow: Boolean,
    close: Function,
    done: Function,
    order: Object
  },
  mounted () {
    this.getPayments()
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      size: '',
      loading: false,
      payTypes: [],
      selectedPaymentName: Object.prototype.hasOwnProperty.call(this.order, 'invoice') ? this.order.invoice.payment_name : this.order.payment_name
    }
  },
  methods: {
    getPayments () {
      mAxios.post('promotion/getPayments').then(response => {
        if (response.data.status === 'success') {
          this.payTypes = response.data.data
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
      })
    },

    setPaymentName (name, id) {
      this.selectedPaymentName = name
      if (name !== 'ebank') {
        this.createInvoice(name, id)
      }
    },

    copy (type) {
      if (type === 'dans') {
        this.$refs.dans.focus()
        document.execCommand('copy')
      } else if (type === 'price') {
        this.$refs.price.focus()
        document.execCommand('copy')
      } else if (type === 'name') {
        this.$refs.name.focus()
        document.execCommand('copy')
      } else if (type === 'value') {
        this.$refs.value.focus()
        document.execCommand('copy')
      }
    }
  }
}
</script>
<style scoped>
.field {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 12px;
}
.copy {
  border: 1px solid;
  border-radius: 50px;
  background-color: #333333;
  color: #fff;
  font-size: 11px;
  padding: 5px 10px;
}
.result {
  border: 1px solid rgb(233, 232, 232);
  padding: 2% 4%;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btnClss {
  width: 50%;
  color: black;
  background-color: #ffb500;
  border-radius: 20px;
  padding-top: 3%;
  padding-left: 1%;
  padding-bottom: 4%;
}

.underList {
  display: flex;
  flex-direction: column;
  gap: 10px; width: 100%;
  justify-content: center;
  align-items: center;
}

.listItem {
  color: #E6E6E6;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
}
</style>
