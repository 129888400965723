<template>
  <div class="discountBody">
    <h1>Хэрэглэгчид суурилсан идэвхижүүлэлт</h1>
    <p style="width:20%; text-align:center">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    <el-button class="button"><i class="el-icon-plus"></i> Шинээр үүсгэх</el-button>
  </div>
</template>
<script>
export default {
  name: 'UserBasedActivation',
  components: {
  }
}
</script>
<style scoped>
div {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.discountBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: rgb(78, 77, 77);
}
.button {
  width: 180px;
  color: black;
  font-weight: bold;
  background-color: #ffb500;
  border-radius: 8px;
}
</style>
